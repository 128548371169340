import { USER_LOGIN_SUCCESS, ADMIN_LOGOUT } from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";
import jwt from "jwt-decode";
import moment from "moment";


export const SendOtp = (mobileNumber) => {
  return (dispatch) => {
    const credentials = { mobileNumber };

    return api
      .post("admin/sendotp", credentials)
      .then((response) => {
        if (response.data.statusCode === 200) {
          message.success("OTP sent successfully.");
          return true; // Indicate success
        } else {
          message.error(response.data.message);
          return false; // Indicate failure
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            message.error(error.response.data.message);
          } else {
            message.error("An error occurred. Please try again.");
          }
        } else {
          message.error("An error occurred. Please try again.");
        }
        return false; // Indicate failure
      });
  };
};

// export const SendOtp = (mobileNumber, navigate) => {
//   return (dispatch) => {
//     const credentials = { mobileNumber };

//     api
//       .post("admin/sendotp", credentials)
//       .then(
//         (response) => {
//           if (response.data.statusCode === 200) {
//             message.success("OTP sent successfully.");
//             // Proceed to the next step in the login flow
//             // navigate("/dfc-payment");
//           } else {
//             message.error(response.data.message);
//           }
//         },
//         (error) => {
//           if (error.response) {
//             if (error.response.status === 403) {
//               message.error(error.response.data.message);
//             } else {
//               message.error("An error occurred. Please try again.");
//             }
//           } else {
//             message.error("An error occurred. Please try again.");
//           }
//         }
//       )
//       .catch((err) => {
//         message.error("An error occurred. Please try again.");
//       });
//   };
// };
export const VerifyOtp = (mobileNumber, otp, navigate) => {
  return (dispatch) => {
    const credentials = {
      mobileNumber,
      otp,
    };

    try {
      api.post("admin/checkotp", credentials).then(
        (response) => {

          let token = response.data.adminToken;
          if (response.data.statusCode === 200) {
            // Set user login state and navigate to dashboard
            dispatch({ type: USER_LOGIN_SUCCESS });
            localStorage.setItem("userId", response.data.userId);
            // localStorage.setItem("Bearer", JSON.stringify(token));
            // SaveDateTimeInToken(token);
            navigate("/user-payment");
          } else {
            message.error(response.data.message);
          }
        },
        (error) => {
          if (
            (error.response && error.response.status === 403) ||
            (error.response && error.response.status === 400)
          ) {
            message.error(error.response.data.message);
          } else {
            errorMessageConfig();
          }
        }
      );
    } catch (err) {
      errorMessageConfig();
    }
  };
};

const errorMessageConfig = () => {
  message.error("An error occurred. Please try again.");
};

export const SaveDateTimeInToken = (token) => {
  let user = jwt(token);
  let expiryDate = new Date(user.exp);

  let DateTimeToken = moment(new Date(expiryDate)).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  localStorage.setItem("DateTimeToken", DateTimeToken);
};
