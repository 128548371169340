
import {
    SUBMIT_LOADER,
    ADD_DEATH_MEMBER,
    NULL_DEATH_MEMBER
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

// death member add
export const addDeathMember = (payload) => {

    return (dispatch) => {
        dispatch(submitloader(true));
        try {
            // console.log("Action payload------> ", payload);
            api.post("admin/users/death/save", payload).then(
                (response) => {
                    if (response?.status === 200) {
                        message.success(response?.data?.message);
                        dispatch({
                            type: ADD_DEATH_MEMBER,
                            payload: response.data.message,
                        });
                    }
                    else {
                        message.error(response?.data?.message);
                    }
                },
                function (error) {
                    if (error.response && error.response.status === 400) {
                        message.error(error?.response?.data?.message);
                        dispatch({
                            type: NULL_DEATH_MEMBER,
                        });
                    }
                }
            );
        } catch (err) {
            console.log("catch err", err);
        }
    };
};

export const submitloader = (para) => {
    return {
        type: SUBMIT_LOADER,
        payload: para,
    };
};

