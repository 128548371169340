import React, { useState } from "react";
import { Button, Input } from "antd";
import _ from "lodash";
import AppConfig from "../../Config/AppConfig";

function openPost(url, params) {
  const formElement = document.createElement("form");
  formElement.setAttribute("method", "post");
  formElement.setAttribute("action", url);
  formElement.setAttribute("target", "_parent");
  _.forEach(params, function (value, key) {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("name", key);
    hiddenField.setAttribute("value", value);
    formElement.appendChild(hiddenField);
  });
  document.body.appendChild(formElement);
  formElement.submit();
}

function UserPaymentCheck({ payableTotalAmount, userId, groupIds }) {
  const handlePayment = () => {
    const params = {
      key: process.env.REACT_APP_PAYMENT_KEY,
      productinfo: process.env.REACT_APP_PAYMENT_PRODUCTINFO,
      surl: AppConfig.baseUrl + "/success.php",
      furl: AppConfig.baseUrl + "error.php",
      service_provider: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER,
      salt: process.env.REACT_APP_PAYMENT_SALT,
      amount: payableTotalAmount.toString(), // Ensure the amount is a string
      user_id: userId,
      group_ids : groupIds
    };

    openPost(AppConfig.apiURL + "/user/payment", params);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "right",
        marginTop: "20px",
      }}
    >
      <Button
        type="primary"
        onClick={handlePayment}
        style={{ borderRadius: "4px" }}
        disabled={!payableTotalAmount}
      >
        Pay
      </Button>
    </div>
  );
}

export default UserPaymentCheck;
