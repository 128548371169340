/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Helmet } from "react-helmet";
// import styles from "../style.module.scss";
import Headernew from '../../Reusable/Main-Layput/Headernew'
// import "../custom.css";
import Footernew from '../../Reusable/Main-Layput/Footernew';
import styles from './style.module.scss'

const Terms = () => {
  return (
    <>
    <Headernew />
    <Helmet title="Terms & Conditions" />
    <section id="slider" className="slider-element swiper_wrapper justify-content-start dark" style={{background: '#063639'}}>
      <div className="container">
        <div className="row h-100 align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 py-5 py-md-0">
            <div className="heading-block border-bottom-0 mb-4">
              <h2 className="mb-4 text-transform-none">Terms & Conditions</h2>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section id="content">
      <div className="content-wrap py-0 overflow-visible">
        <div className="section mt-3" style={{background: 'url("demos/nonprofit/images/others/section-bg.jpg") no-repeat center center / cover', padding: '40px 0'}}>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">Qualification for Membership</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>Person above the age of 18 of years belonging to Samasta Dashashrimali Sukhadiya caste can become a Member.</li>
                      <li>The person till the implementation of the scheme commences, if has paid the admission fee, membership fee and ADFC total amount payable him, only such a person will be admitted as the Member.</li>
                      <li>In the scheme framed in the beginning of the Trust, the member admitted at the relevant time, the rules framed in that Scheme,  shall be applicable to such Member.</li>
                      <li>The powers to admit or reject the Membership for any person shall be vested with the Board of Trustees.</li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">Rules for Getting the Membership</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>person belonging DashaShrimali Sukhadiya Community for getting the Membership is required to submit the prescribed application form after completely filling up the details.</li>
                      <li>Each person is required to furnish the details of names, addresses etc. of two Nominees in the application form.</li>
                      <li>Along with the application form, two passport size photographs are required to be given.</li>
                      <li>For the proof of age, any one of the government documents showing the date of birth is required to be submitted along with the application form.</li>
                      <li>The total amount payable as per the age, is required to be drawn in the name of Shri Samasta Dashashrimali Sukhadiya Suraksha Trust.</li>
                      <li>Membership Fee, admission fee, administrative contribution is non refundable.</li>
                      <li>The amount of ADFC, for the amount of contribution of DFC to be paid by a Member, is required to be given as security deposit.</li>
                      <li>From the ADFC amount, the unused amount will be refunded.</li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">Nominee</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>Each member is required to furnish names, addresses of two persons as Nominees.</li>
                      <li>Any one of the nominee is required to obtain the Membership of the institution. Thereafter they shall be paid the DFC contribution.</li>
                      <li>After the death of a Member, the amount  will be paid to the first Nominee.</li>
                      <li>During the financial year, only once the facility for changing the Nominee has been provided and towards administrative charges an amount of Rs.150/- is to be paid.</li>
                      <li>Between 1st and 5th of each month new Member will be taken.</li>
                      <li>A member at the time of getting admitted, if any of the details filled in the form is changed, then its written intimation is required to be given to the office address of the institution (for correspondence).</li>
                      <li>In case of an unmarried female Member, after her marriage, if any change in the Membership Form filled up by her, then attested Xerox copy of her required to be submitted.</li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">DFC Contribution</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>The intimation for payment of DFC by letter or through any other medium will be given.</li>
                      <li>Upon death of every three Members, the amount intimated vide letter of any other medium, is required to be paid by the Member, which is to be paid  within 2 (two) months. thereafter, it will be received in the grace period it will be received with late fee of Rs.50/- and despite this within 3 (three) months a Member who does not pay the DFC amount his Membership will be automatically cancelled. A Member so cancelled will have to become the Member again.</li>
                      <li>Per death of any person, the remaining Members are required to pay Rs.100/- out of which Rs.80/-- will be paid to the nominee of the deceased Member and balance Rs.20/- shall be credited in Corpus fund.</li>
                      <li>Each member is required to pay continuously the DFC contribution for 20 years, and thereafter that Member shall stop payment of the contribution but his ownership Shall continue and his DFC contribution shall be paid out of the corpus fund.</li>
                      <li>The amount of DFC contribution is to be paid by  cheque/DD </li>
                      <li>The DFC amount with late fee is required to be paid only in cash.</li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">Rights of Member</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>After the death of the Member, his first Nominee shall be paid the amount of DFC. This the amount shall be received in proportion to Member registered before fifth of the previous month from of the date of1 the death (DFC amount number of members x 80 Rs.)</li>
                      <li>The un-deducted amount from the ADFC.</li>
                      <li>A Member who has paid the ADFC contribution continuously for 20 years, to such Member, against his credit of amount ADFC, after completion of 20 years, it will be returned.</li>
                      <li>Contingency medical assistance shall be applicable to a Member continuously holding the Membership for 20 years.
                        <ol style={{padding: '20px'}}>
                          <li>Major surgery, Open Heart surgery angioplasty, brain surgery, kidney transplant, paralysis, incorporate total joint replacement surgery, cancer (the Board of Trustee can changes in this list from time to time).</li>
                          <li>For contingency medical assistance, each Member is required to make an application within 60 days of the operation or sickness in the form prescribed by the institution.</li>
                          <li>In such case, whatever members are there on the 5th of the previous month, per such each member at the rate of Rs.10/- the assistance will be given.</li>
                          <li>The Member can get this financial assistance only once.</li>
                          <li>After the death of the Member getting this assistance, the amount receivable by him shall be deducted from the DFC amount, the amount given to him as assistance and balance amount shall be paid to his nominee or heir.</li>
                        </ol>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">Voluntary Retirement</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>If any Member desires, then after years he can retire from this scheme.</li>
                      <li>A Member retiring in this manner shall be refunded only the unutilized amount from the ADFC amount.</li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">Cancellation of Membership</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>Upon death of any Member, his Membership shall be considered cancelled.</li>
                      <li>Any female Member getting married to a person not belonging to Dashashrimali Sukhadiya community, her Membership shall be automatically cancelled. Member getting cancelled in this manner, shall be refunded only the unutilized ADFC amount.</li>
                      <li>A member not paying within the time limit the DFC contribution his membership shall be automatically cancelled. The said Member shall be refunded only the unutilized ADFC amount.</li>
                      <li>Any Member if found showing the causing damage to the interest of the Trust or if in the capacity of office bearer acts against will the Rules and Regulations of Board of Trustees, then the Board of Trustees by majority, take the decision and the said decision cannot be challenged in any manner and also it will bela final and binding to all.</li>
                      <li>Upon cancellation of any Member of the institution, immediately, then the said Member will be automatically removed from all the posts held by him in the institution.</li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">General Rules</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>Between 1st and 5th of every month Members will be made.</li>
                      <li>Each person can be Member in each Scheme as per its Rules and Regulations.</li>
                      <li>Change in respect to the following details can be incorporated by paying Rs.100/-.
                        <ol style={{padding: '20px'}}>
                          <li>Address</li>
                          <li>Telephone number</li>
                          <li>E mail address</li>
                          <li>WhatsApp number</li>
                        </ol>
                      </li>
                      <li>Any female Member if is required to make requisite in her name then along with change copies of the true papers, application is required to be made to the institution and fee of Rs.100/- is to be paid for that purpose.</li>
                      <li>For change in nominee, the requisite papers submitted and its fee of are required to  be Rs.400/- is to be paid.</li>
                      <li>For getting the duplicate certificate, requisite papers are required to be submitted and fee of Rs.400/- for the same is to be paid.</li>
                      <li>Such change can be done only once in a financial year.</li>
                      <li>The Rules and Regulations and amendments to be incorporated in future, will be binding to each Member and his beneficiary.</li>
                    </ul>
                </div>
              </div>
            </div>
        </div>
      </div>
      
    </section>
    
    <Footernew />
    </>

  )
}

export default Terms
