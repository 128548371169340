import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, Navigate } from "react-router-dom";
import { Form, Input, Layout, message } from "antd";
import { SiteConstants } from "../../../Config/SiteConstants";
import { PhoneOutlined, LockOutlined, EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import mainLogo from "../../../assets/images/logo.png";
import { SendOtp, VerifyOtp } from "../../../Action/UserAuth";
import { auth } from '../../../Config/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const mapDispatch = (dispatch) => {
  return {
    SendOtpDispatch: (mobileNumber) => dispatch(SendOtp(mobileNumber)),
    LoginDispatch: (mobileNumber, otp, navigate) =>
      dispatch(VerifyOtp(mobileNumber, otp, navigate)),
  };
};

const mapState = (props) => ({
  CheckLogin: props.AuthReducer.isLoggedInAdminState,
});



function Login(store) {
  const navigate = useNavigate();
  const [mobileNumber, setMobile] = useState("+91");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1); // 1: Mobile input, 2: OTP input with edit option
  const { Footer } = Layout;

  const [phone, setPhone] = useState('');
  const [hasFilled, setHasFilled] = useState(false);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
      'size': 'invisible',
      'callback': (response) => {
      
      }
    });
  }

  const handleSend = async (event) => {
    event.preventDefault();
    const success = await store.SendOtpDispatch(phone);
    if (success) {
        const userPhone = "+91" + phone;
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, userPhone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent
        window.confirmationResult = confirmationResult;
        setHasFilled(true);

      }).catch((error) => {
        // Error;
        console.log(error);
      });
    }
  }
  
  const verifyOtp = (event) => {
    event.preventDefault();

    if (otp.length === 6) {
      // verifyy otp
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {

        let user = result.user;
        console.log(user);
        alert('User signed in successfully');
        store.LoginDispatch(phone, otp, navigate);
      }).catch((error) => {
        // User couldn't sign up
        alert('User couldn\'t sign in (bad verification code?)');
      });
    }
  }

  const onLoginSubmit = () => {
    store.LoginDispatch(mobileNumber, otp, navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login - ${SiteConstants.site_name}`}</title>
      </Helmet>
      {store.CheckLogin === true && <Navigate to="/dashboard" />}

      <div className="divBg">
        <div className="logoDiv">
          <img src={mainLogo} alt="logo" style={{ width: "8%" }} />
        </div>

        <div className="center">
          <div className="containerLogin loginform">
            <h2>
              <strong>Login</strong>
            </h2>

      { !hasFilled  &&
      <div className='app__container'>
        <Card sx={{ width: '300px'}}>
          <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <form onSubmit={handleSend}>
              <TextField sx={{ width: '240px'}} variant='outlined' autoComplete='off' label='Phone Number' value={phone} onChange={(event) => setPhone(event.target.value)} />
              <Button type='submit' variant='contained' sx={{ width: '240px', marginTop: '20px'}}>Send Code</Button>
            </form>
          </CardContent>
        </Card>
        <div id="recaptcha"></div>
      </div>
  }  { hasFilled &&
      <div className='app__container'>
        <Card sx={{ width: '300px'}}>
          <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Typography sx={{ padding: '20px'}} variant='h5' component='div'>Enter the OTP</Typography>
              <form onSubmit={verifyOtp}>
                <TextField sx={{ width: '240px'}} variant='outlined' label='OTP ' value={otp}  onChange={(event) => setOtp(event.target.value)}/>
                <Button type='submit' variant='contained' sx={{ width: '240px', marginTop: '20px'}}>Login</Button>
              </form>
          </CardContent>
        </Card>
        <div id="recaptcha"></div>
      </div>
  }
          </div>
        </div>

        <Footer
          style={{
            textAlign: "center",
            position: "fixed",
            bottom: "0px",
            width: "100%",
            color: "white",
            backgroundColor: "transparent",
          }}
        >
          <div style={{ margin: "10px" }}>
            <Link className="link" to="/terms-conditions">
              Terms & Conditions
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/privacy-policy">
              Privacy Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/refund-policy">
              Refund Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/contact-us">
              Contact Us
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/about-us">
              About Us
            </Link>
          </div>
          © {new Date().getFullYear()} {SiteConstants.site_name} · All Right
          Reserved
        </Footer>
      </div>
    </React.Fragment>
  );
}

export default connect(mapState, mapDispatch)(Login);