import {
    GET_SETTING,
    SETTING_LOADER,
    SETTING_FAIL,
} from "../Action/ActionTypes";

const initialState = {
    success: false,
    settingState: null,
    error: false,
    settingloading: false,
};

export default function SettingReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SETTING:

            return {
                ...state,
                success: true,
                settingloading: false,
                settingState: payload,
            };

        case SETTING_LOADER:

            return {
                ...state,
                settingloading: payload,
            };
        case SETTING_FAIL:

            return {
                ...state,
                error: true,
                settingloading: false,
            };

        default:

            return state;
    }

}
