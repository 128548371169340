import {
    DFC_FEES_ADD,
    ADD_MEMBER_LOADING,
    //LOADER,
    //INITIALIZE_STATE,

} from "../Action/ActionTypes";

const initialState = {

    success: false,
    successDetails: null,
    error: false,
    isloading: false,
};

export default function FeesReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_MEMBER_LOADING:
            return { ...state, isloading: true };

        case DFC_FEES_ADD:

            return {
                ...state,
                success: true,
                successDetails: payload,
            };

        default:
            return state;
    }

}
