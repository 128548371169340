import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, Navigate } from "react-router-dom";
import "./login.scss";
import { Button, Form, Input, Layout } from "antd";
import { SiteConstants } from "../../../Config/SiteConstants";
import ReCAPTCHA from "react-google-recaptcha";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import mainLogo from "../../../assets/images/logo.png";
import { LoginCheck } from "../../../Action/AdminAuth";

const mapDispatch = (dispatch) => {
  return {
    LoginDispatch: (email, password, navigate, tokenRecaptcha) =>
      dispatch(LoginCheck(email, password, navigate, tokenRecaptcha)),
  };
};

const mapState = (props) => ({
  CheckLogin: props.AuthReducer.isLoggedInAdminState,
});

function Login(store) {
  // const [form] = Form.useForm();
  let navigate = useNavigate();
  const reCaptchaRef = useRef();
  const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  const { Footer } = Layout;

  const onFinish = () => {
    store.LoginDispatch(email, password, navigate, tokenRecaptcha);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(value) {
    console.log("value: ", value);
    setTokenRecaptcha(value);
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login - ${SiteConstants.site_name} `}</title>
      </Helmet>
      {store.CheckLogin === true && <Navigate to="/dashboard" />}

      <div className="divBg">
        <div className="logoDiv">
          <img src={mainLogo} alt="logo" style={{ width: "8%" }} />
        </div>

        <div className="center">
          <div className="containerLogin loginform">
            {/* <div className="text">Login</div> */}

            <h2>
              <strong>Login</strong>
            </h2>

            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="data">
                <label>
                  <b className="asterisk">*</b> Email
                </label>

                <Form.Item
                  type="text"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="data">
                <label>
                  <b className="asterisk">*</b> Password
                </label>

                <Form.Item
                  type="password"
                  // name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="data">
                <Form.Item
                  name="reCAPTCHA"
                  rules={[
                    {
                      required: true,
                      message: "reCAPTCHA is required",
                    },
                  ]}
                >
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={SiteConstants.googleRecaptchaKey.siteKey}
                    size="normal"
                    onChange={onChange}
                    onExpired={(e) => setTokenRecaptcha("")}
                    onErrored={(e) => setTokenRecaptcha("")}
                  />
                </Form.Item>
              </div>

              {/* <div className="btn login"> */}
              <div className="inner"></div>
              <div className="login-footer-contant"><Button type="primary" htmlType="submit" className="button-login">
                Login
              </Button>          
            <Link className="payment-check-link" to="/dfc-payment">
            Check Payment
            </Link>
            </div>
              
              {/* </div> */}
            </Form>
          </div>
        </div>


        <Footer
          style={{
            textAlign: "center",
            position: "fixed",
            bottom: "0px",
            width: "100%",
            color: "white",
            backgroundColor: "transparent"
          }}
        >
          <div style={{ margin: "10px" }}>
            <Link className="link" to="/terms-conditions">
              Terms & Conditions
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/privacy-policy">
              Privacy Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/refund-policy">
              Refund Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/contact-us">
              Contact Us
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/about-us">
              About Us
            </Link>
          </div>
          © {new Date().getFullYear()} {SiteConstants.site_name} · All Right
          Reserved
        </Footer>
      </div>
    </React.Fragment>
  );
}

export default connect(mapState, mapDispatch)(Login);
