import {
    TRANSACTION_LIST,
    TRANSACTION_LOADING,
    INITIALIZE_STATE,
    LOADER
  } from "../Action/ActionTypes";
  
  const initialState = {
    isloading: true,
    TransactionList: [],
    TransactionDetails: null,
    success: false,
    error: false,
    errorDetails: null,
  };
  
  export default function TransactionReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case TRANSACTION_LOADING:
        return { ...state, isloading: true };
  
    //   case EDIT_MEMBER_LOADING:
    //     return { ...state, isloading: true };
  
      case TRANSACTION_LIST:
        return {
          ...state,
          isloading: false,
          success: true,
          error: false,
          errorDetails: null,
          TransactionList: payload,
        };
  
      // Add Error
      // case ADD_MEMBER_ERROR:
      //   return {
      //     ...state,
      //     isloading: false,
      //     success: false,
      //     error: true,
      //     errorDetails: payload,
      //     successDetails: null,
      //   };
  
      // Edit Success
    //   case EDIT_MEMBER_SUCCESS:
    //     return {
    //       ...state,
    //       isloading: false,
    //       success: true,
    //       error: false,
    //       errorDetails: null,
    //       successDetails: payload,
    //     };
  
      // Edit Error
      // case EDIT_MEMBER_ERROR:
      //   return {
      //     ...state,
      //     isloading: false,
      //     success: false,
      //     error: true,
      //     errorDetails: payload,
      //     successDetails: payload,
      //   };
  
    //   case MEMBER_DETAILS: {
    //     return {
    //       ...state,
    //       MemberDetails: payload,
    //     };
    //   }

  
      case LOADER:
        return {
          ...state,
          isloading: payload,
        };
  
      case INITIALIZE_STATE:
        return {
          ...state,
          successDetails: null,
          success: false,
        };
  
      default:
        return state;
    }
  }
  