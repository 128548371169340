import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function RequireAuth() {
  const isLoggedInAdmin = useSelector((state) => state.AuthReducer);

  if (isLoggedInAdmin.isLoggedInAdminState === true) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
}

export default RequireAuth;
