import {
    ADD_SETTING_LOADER,
    ADD_SETTING,
    NULL_SETTING,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";


// add setting
export const addSetting = (payload) => {

    return (dispatch) => {
        dispatch(loader(true));
        try {

            api.post("admin/setting", payload).then(
                (response) => {
                    if (response?.status === 200) {
                        let successdata = response?.data?.message
                        message.success(successdata);
                        dispatch({
                            type: ADD_SETTING,
                            payload: successdata,
                        });
                    }
                    else {
                        message.error(response?.data?.message);
                    }
                },
                function (error) {
                    if (error.response && error.response.status === 400) {
                        let errordata = error?.response?.data?.message;
                        message.error(errordata);

                        dispatch({
                            type: NULL_SETTING,
                            payload: errordata,
                        });
                    }
                }
            );
        } catch (err) {
            console.log("catch err", err);
        }
    };
};

export const loader = (para) => {
    return {
        type: ADD_SETTING_LOADER,
        payload: para,
    };
};