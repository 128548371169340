/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Helmet } from "react-helmet";
// import styles from "../style.module.scss";
import Headernew from '../../Reusable/Main-Layput/Headernew'
import "../custom.css";
import Footernew from '../../Reusable/Main-Layput/Footernew';
import styles from './style.module.scss'

function Refund() {
  return (
    <>
    <Headernew />
    <Helmet title="Refund Policy" />
    <section id="slider" className="slider-element swiper_wrapper justify-content-start dark" style={{background: '#063639'}}>
      <div className="container">
        <div className="row h-100 align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 py-5 py-md-0">
            <div className="heading-block border-bottom-0 mb-4">
              <h2 className="mb-4 text-transform-none">Refund Policy</h2>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section id="content">
      <div className="content-wrap py-0 overflow-visible">
        <div className="section mt-3" style={{background: 'url("demos/nonprofit/images/others/section-bg.jpg") no-repeat center center / cover', padding: '0 0 250px 0'}}>
            <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                    <h4 className="mb-2">Refund Policy</h4>
                    <div className="svg-line mb-2">
                      <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                    </div>
                    <ul>
                      <li>A person getting admitted as a new Member from 1/10/2007, is required to pay compulsorily at a time as per the age the admission fee, Membership fee and ADFC amount.</li>
                      <li>At the time of cancellation Membership, out of the amount of ADFC, deducting the due DFC amount the balance amount will be refunded.</li>
                      <li>The amount of admission fee will be in Corpus Fund which will not be deposited refunded. The amount of Membership Fee will be deposited Membership Fee fund which will not be refunded.</li>
                      <li>A member not paying within the time limit the DFC contribution his membership shall be automatically cancelled. The said Member shall be refunded only the unutilized ADFC amount.</li>
                      <li>Any Member if found showing the causing damage to the interest of the Trust or if in the capacity of office bearer acts against will the Rules and Regulations of Board of Trustees, then the Board of Trustees by majority, take the decision and the said decision cannot be challenged in any manner and also it will be final and binding to all.</li>
                      <li>Upon cancellation of any Member of the institution, immediately, then the said Member will be automatically removed from all the posts held by him in the institution.</li>
                    </ul>
                </div>
              </div>
            </div>
            <br/>
            <br/>
        </div>
      </div>
      
    </section>
    
    <Footernew />
    </>
  );
}

export default Refund;
