import { ADMIN_LOGIN_SUCCESS, ADMIN_LOGOUT } from "../Action/ActionTypes";

let data = JSON.parse(localStorage.getItem("Bearer"));

const initialState = {
  tokenState: [],
  isLoggedInAdminState: data && data ? true : false,
};

export default function AuthReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedInAdminState: payload.isLoggedInAdminState,
        tokenState: payload.tokenState,
      };

    case ADMIN_LOGOUT:
      return {
        isLoggedInAdminState: false,
      };

    default:
      return state;
  }
}
