/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Table, message, Space, Button, Input, Tooltip } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import api from "../../Config/Api";

import {
  // EyeOutlined,
  SearchOutlined,
  EditFilled,
  // QuestionCircleOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { getDeathList } from "../../Action/Death";

// import "./userstyle.scss";
// import "./style.css";

const mapState = (props) => ({
  DeathList: props?.DeathReducer?.deathList,
  Death: props?.DeathReducer?.deathListSuccess,
  SelectLoader: props?.DeathReducer?.isloading,
});

const mapDispatch = (dispatch) => {
  return {
    GetDeathList: () => dispatch(getDeathList()),
  };
};

function DeathMemberList(store) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  let [DeathArray, setDeathArray] = useState([]);
  console.log("DeathArray: ", DeathArray);
  const [pdfloader, setpdfloader] = useState(false);

  // //  start deep ....................

  // const memberactionstatus = useSelector(state => state.MemberReducer.MmberStatus);

  // function handleMemberStatus(checked, _id) {
  //     let statusaction = checked ? "ACTIVE" : "INACTIVE";

  //     // confirm({
  //     //   title: `Are you sure you want to  ${statusaction.toLowerCase()}  this user?`,
  //     //   icon: <QuestionCircleOutlined style={{ color: "#030d46" }} />,
  //     //   okText: "Yes",
  //     //   okType: "primary",
  //     //   cancelText: "No",

  //     //   async onOk() {
  //     const payload = {
  //         _id,
  //         status: statusaction,
  //     }

  //     store?.MemberStatus(payload);
  //     //   },
  //     //   onCancel() {
  //     //     // setClickSwitchID(null);
  //     //   },
  //     // });
  // }

  // useEffect(() => {
  //     if (memberactionstatus !== null) {
  //         store.MemberList();
  //         message.success(memberactionstatus);
  //     }
  // }, [memberactionstatus]);

  // //const [status, setStatus] = useState("ACTIVE");

  // // end deep ........................

  useEffect(() => {
    store.GetDeathList();
  }, []);

  useEffect(() => {
    if (store?.Death) {
      // console.log(store?.DeathList);
      setDeathArray(store?.DeathList);
    }
  }, [store?.Death]);

  const generatepdfAPI = async () => {
    try {
      setpdfloader(true);
      // console.log("Action payload------> ", payload);
      await api.post("admin/generatePdf").then((response) => {
        if (response?.status === 200) {
          setpdfloader(false);
          message.success(response?.data?.message);
        } else {
          message.error(response?.data?.message);
        }
      });
    } catch (err) {
      console.log("catch err", err);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true, closeDropdown: true });
    setSearchText("");
  };

  let dataSource =
    DeathArray &&
    DeathArray?.map((data, index) => ({
      key: index,
      id: data?._id,
      member: `${data?.refmemberId?.firstname} 
                ${data?.refmemberId?.middlename} 
                ${data?.refmemberId?.lastname}`,
      location: data?.deathCity,
      deathdate:
        moment(new Date(data?.DeathOnUTC)).format("DD/MM/YYYY") || "NA",
      informhdate:
        moment(new Date(data?.InformOnUTC)).format("DD/MM/YYYY") || "NA",
      payableamount: data?.payableamount.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
      }),
    }));
  // strat from here here here......................

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 112,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 112,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      ...getColumnSearchProps("member", "Member"),
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ...getColumnSearchProps("location", "Location"),
    },

    {
      title: "Payable Amount",
      dataIndex: "payableamount",
      key: "payableamount",
      ...getColumnSearchProps("payableamount", "Payable Amount"),
    },

    {
      title: "Death Date",
      dataIndex: "deathdate",
      key: "deathdate",
      ...getColumnSearchProps("deathdate", "Death Date"),
    },

    {
      title: "Inform Date",
      dataIndex: "informhdate",
      key: "informhdate",
      ...getColumnSearchProps("informhdate", "Inform Date"),
    },
    // start deep ............................
    // {
    //     title: "Status",
    //     dataIndex: "status",
    //     render: (text, record) => (
    //         <Space size="middle">
    //             <Tooltip title={record.status ? "ACTIVE" : "INACTIVE"}>
    //                 <Switch
    //                     key={record.id}
    //                     checked={record.status && true}
    //                     onChange={(checked) => handleMemberStatus(checked, record.id)}
    //                 />
    //             </Tooltip>
    //         </Space>
    //     ),
    // },
    // end deep ...........................

    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Link to={`/edit-death/${record?.id}`}>
              <Button
                style={{ background: "black", border: "black" }}
                type="primary"
                icon={<EditFilled />}
              />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <SiteLayout>
      {store?.SelectLoader === true && <Loader />}
      {pdfloader === true && <Loader />}

      <div
        style={{ padding: "30px" }}
        className="checkout-form-container gig-sure-card-wrapper"
      >
        <div className="wrapper_twoDiv">
          <Space style={{ marginBottom: 18 }}>
            <h3 className="start">Death List</h3>
          </Space>
          <div className="end">
            <Space style={{ marginBottom: 1 }}>
              <Link to="/add-death">
                <Button className="primary custom-btn">Add Death</Button>
              </Link>
            </Space>

            {/* <Space style={{ marginBottom: 1, marginLeft: 20 }}>
                            <Link to="">

                                <Button
                                    className="primary custom-btn"
                                    onClick={generatepdfAPI}
                                >Generate PDF
                                </Button>
                            </Link>
                        </Space> */}
          </div>
        </div>

        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(DeathMemberList);
