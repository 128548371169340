/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import "../Members/style.css";
import {
    Button,
    Space,
    DatePicker,
    Radio,
    Form,
    Input,
    Row,
    Col,
    Typography,
    Select,
} from "antd";

import { AddDfcFees } from "../../Action/DfcFees"
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;


// Action
const mapDispatch = (dispatch) => {
    return {
        // fees action call
        addFess: (payload) => dispatch(AddDfcFees(payload))
    };
};

const mapState = (props) => ({
    SelectLoader: props?.MemberReducer?.isloading,
    // ResponseCode: props?.MemberReducer?.ResponseCode,
    // error: props?.MemberReducer?.error,
    // errorDetails: props?.MemberReducer?.errorDetails,
    success: props?.MemberReducer?.success,
    successDetails: props?.MemberReducer?.successDetails,
    MemberDetails: props?.MemberReducer?.MemberDetails,
});

function AddFees(store) {
    const [form] = Form.useForm();
    const formRef = useRef();
    const { id } = useParams();
    const navigate = useNavigate();

    const dfcdata = useSelector(state => state.FeesReducer);

    // Edit
    useEffect(() => {
        id && store?.SingalMemberDetail(id);
        // console.log("data--------->", dfcdata); 
        // eslint-disable-next-line
    }, [id, dfcdata]);


    useEffect(() => {
        form.setFieldsValue({
            PaymentMethod: "cash",
            // by default payment method is cash in payload......
        });
        //eslint-disable-next-line
    }, [])

    // Edit
    // useEffect(() => {
    //     if (id) {
    //         if (store?.MemberDetails) {
    //             form.setFieldsValue({
    //                 firstname:
    //                     store?.MemberDetails?.firstname?.split(".")?.length > 1
    //                         ? store?.MemberDetails?.firstname?.split(".")?.[1]?.toString()
    //                         : store?.MemberDetails?.firstname?.split(".")?.[0]?.toString(),
    //                 prefix:
    //                     store?.MemberDetails?.firstname?.split(".")?.length > 1
    //                         ? store?.MemberDetails?.firstname?.split(".")?.[0]?.toString() +
    //                         "."
    //                         : "Mr.",

    //                 addressLine1: store?.MemberDetails?.addressLine1,
    //                 addressLine2: store?.MemberDetails?.addressLine2,
    //                 addressLine3: store?.MemberDetails?.addressLine3,
    //                 City: store?.MemberDetails?.City,
    //                 district: store?.MemberDetails?.district,
    //                 education: store?.MemberDetails?.education,
    //                 email: store?.MemberDetails?.email,
    //                 gender: store?.MemberDetails?.gender,
    //                 group: store?.MemberDetails?.group,
    //                 internalGroupId: store?.MemberDetails?.internalGroupId,
    //                 joiningDate: moment(
    //                     new Date(store?.MemberDetails?.joiningDate)?.toLocaleDateString(),
    //                     "DD-MM-YYYY"
    //                 ),
    //                 landmark: store?.MemberDetails?.landmark,
    //                 lastname: store?.MemberDetails?.lastname,
    //                 maritalStatus: store?.MemberDetails?.maritalStatus,
    //                 middlename: store?.MemberDetails?.middlename,
    //                 mobileNumber1: store?.MemberDetails?.mobileNumber1,
    //                 mobileNumber2: store?.MemberDetails?.mobileNumber2,
    //                 pincode: store?.MemberDetails?.pincode,
    //                 state: store?.MemberDetails?.state,
    //                 watsappMobileNumber: store?.MemberDetails?.watsappMobileNumber,
    //                 membership_status: store?.MemberDetails?.membershipStatus,

    //                 nominationName1: store?.MemberDetails?.nomination?.name,
    //                 nominee_dob_1: moment(
    //                     new Date(
    //                         store?.MemberDetails?.nomination?.birthdate
    //                     )?.toLocaleDateString(),
    //                     "DD-MM-YYYY"
    //                 ),
    //                 nominationMemberRelation1: store?.MemberDetails?.nomination?.relation,
    //                 memberId1: store?.MemberDetails?.nomination?.memberId,

    //                 nominationName2: store?.MemberDetails?.nomination2?.name,
    //                 nominee_dob_2: moment(
    //                     new Date(
    //                         store?.MemberDetails?.nomination2?.birthdate
    //                     )?.toLocaleDateString(),
    //                     "DD-MM-YYYY"
    //                 ),
    //                 nominationMemberRelation2:
    //                     store?.MemberDetails?.nomination2?.relation,
    //                 memberId2: store?.MemberDetails?.nomination2?.memberId,
    //             });
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [store?.MemberDetails, id]);

    // useEffect(() => {
    //   if (store.error && store?.errorDetails?.response?.status !== 400) {
    // }, [store.error]);

    useEffect(() => {
        if (store?.success && store?.successDetails?.data?.message) {
            console.log("here-succc");
            form.resetFields();
            store.resetState();
        }

        // eslint-disable-next-line
    }, [store?.success && store?.successDetails?.data?.message]);

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    function handleFormSubmit(params) {

        // pass payload to action and reducer
        let payload = {
            memberdetails: [
                {
                    memberId1: params?.MemberId1,
                    fees1: params?.Fees1,
                    charges1: params?.Charges1,
                },
                {
                    memberId2: params?.MemberId2,
                    fees2: params?.Fees2,
                    charges2: params?.Charges2,
                }
            ],
            payment: params?.PaymentMethod,
            receiptno: params?.ReceiptNo,
            receiptdate: params?.ReceiptDate,
        };

        if (params?.Group) {
            payload["group"] = params?.Group;
        }

        if (params?.FirstMember) {
            payload["firstmember"] = params?.FirstMember;
        }

        if (params?.PaymentMethod === "cheque") {
            payload["chequedetail"] = {
                bank: params?.BankName,
                branch: params?.BranchName,
                cheque: params?.ChequeNo,
                chequedate: params?.ChequeDate,
            }
        }
        store.addFess(payload);

    }

    // set member details auto when group id is not null
    const setmembervalue = (value) => {
        if (value) {
            form.setFieldsValue({
                MemberId1: "100",
                Member1: "member 1",
                City1: "Rajkot",
                Fees1: 100,
                Charges1: 0,
                MemberId2: "101",
                Member2: "member 2",
                City2: "Gondal",
                Fees2: 100,
                Charges2: 50
            });
        }
        else {
            form.setFieldsValue({
                MemberId1: null,
                member: null,
                City1: null,
                Fees1: null,
                Charges1: null,
                MemberId2: null,
                Member2: null,
                City2: null,
                Fees2: null,
                Charges2: null
            })
        }
    }

    const [value, setValue] = useState('cash');
    // const [member, setMember] = useState({
    //     memberId: null,
    //     member: null,
    //     city: null,
    //     fees: null,
    //     lateFees: null
    // });

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const [groupData, setGroupData] = useState('');

    const onGroupDataChange = (e) => {
        setGroupData(e.target.value);
        // call function of set member value
        setmembervalue(e.target.value);

    };

    const [firstData, setFirstData] = useState('');

    const onFirstDataChange = (e) => {
        setFirstData(e.target.value);
        setmembervalue(e.target.value);
    };

    return (
        <SiteLayout>
            <div className="headingDiv">
                <h2 style={{ textAlign: "center", margin: "0px" }}>
                    DFC Fees
                </h2>
            </div>

            <div className="subscription-container center-content">
                <Row gutter={2}>
                    <Col
                        //changes start
                        xxl={20}
                        xl={20}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ margin: "0 auto" }}
                    //changes end
                    >
                        <div
                            style={{ padding: "30px" }}
                            className="checkout-form-container gig-sure-card-wrapper"
                        >
                            <Form
                                scrollToFirstError={true}
                                onFinish={handleFormSubmit}
                                onFinishFailed={onFinishFailed}
                                layout="vertical"
                                form={form}
                                name="dfc"
                                autoComplete="off"
                                ref={formRef}
                            >
                                {/* DFC old design  */}

                                {/* <Row gutter={10}>
                                    <Col xl={4} lg={4} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="DFC"
                                            label="DFC #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "DFC is required",
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid DFC",
                                                },
                                            ]}
                                        >
                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="DFC"

                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={20} lg={20} md={16} sm={24} xs={24}>
                                        <Form.Item label="Details">
                                            <TextArea rows={2} disabled={true} />

                                        </Form.Item>
                                    </Col>

                                    <Col xl={4} lg={7} md={10} sm={24} xs={24}>
                                        <Form.Item
                                            name="Group"
                                            label="Group #"
                                            rules={[
                                                {
                                                    //required: true,
                                                    // message: "Email is required",
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Group Id",
                                                },
                                            ]}
                                        >
                                            {firstData === '' ?
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="Group Id"
                                                    value={groupData}
                                                    onChange={onGroupDataChange}
                                                    disabled={false}
                                                /> :
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="Group Id"
                                                    value={groupData}
                                                    onChange={onGroupDataChange}
                                                    disabled={true}
                                                />
                                            }

                                        </Form.Item>

                                    </Col>
                                    <Col colSpan={1}>
                                        <h3 style={{ margin: '42px 15px' }}> OR </h3>
                                    </Col>

                                    <Col xl={4} lg={7} md={10} sm={24} xs={24}>
                                        <Form.Item
                                            name="First Member"
                                            label="First Member #"
                                            rules={[
                                                {
                                                    //required: true,
                                                    // message: "Email is required",
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Group Id",
                                                },
                                            ]}
                                        >
                                            {groupData === '' ?
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="First Member Id"
                                                    onChange={onFirstDataChange}
                                                    value={firstData}
                                                    disabled={false}
                                                /> :
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="First Member Id"
                                                    onChange={onFirstDataChange}
                                                    value={firstData}
                                                    disabled={true}
                                                />}
                                        </Form.Item>

                                    </Col>
                                </Row>  */}

                                <Row className="mt-1">
                                    <Col span={24}>
                                        <Title level={4}>DFC Details</Title>
                                    </Col>
                                </Row>

                                <Row gutter={10}>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            name="DFC"
                                            label="DFC #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "DFC is required",
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid DFC",
                                                },
                                            ]}
                                        >
                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="DFC"

                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item label="Details">
                                            <TextArea rows={5} disabled={true} />

                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="mt-1">
                                    <Col span={24}>
                                        <Title level={4}>Group Details</Title>
                                    </Col>
                                </Row>

                                <Row gutter={10}>
                                    <Col xl={4} lg={7} md={10} sm={24} xs={24}>
                                        <Form.Item
                                            name="Group"
                                            label="Group #"
                                            rules={[
                                                // {
                                                //     //required: true,
                                                //     // message: "Email is required",
                                                // },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Group Id",
                                                },
                                            ]}
                                        >
                                            {firstData === '' ?
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="Group Id"
                                                    value={groupData}
                                                    onChange={onGroupDataChange}
                                                    disabled={false}
                                                /> :
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="Group Id"
                                                    value={groupData}
                                                    // onChange={onGroupDataChange}
                                                    disabled={true}
                                                />
                                            }

                                        </Form.Item>

                                    </Col>
                                    <Col colSpan={1}>
                                        <h3 style={{ margin: '42px 15px' }}> OR </h3>
                                    </Col>

                                    <Col xl={4} lg={7} md={10} sm={24} xs={24}>
                                        <Form.Item
                                            name="FirstMember"
                                            label="First Member #"
                                            rules={[
                                                // {
                                                //     //required: true,
                                                //     // message: "Email is required",
                                                // },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Group Id",
                                                },
                                            ]}
                                        >
                                            {groupData === '' ?
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="First Member Id"
                                                    onChange={onFirstDataChange}
                                                    value={firstData}
                                                    disabled={false}
                                                /> :
                                                <Input
                                                    className="form-input-border"
                                                    autoComplete="off"
                                                    placeholder="First Member Id"
                                                    onChange={onFirstDataChange}
                                                    value={firstData}
                                                    disabled={true}
                                                />}
                                        </Form.Item>

                                    </Col>
                                </Row>
                                {/* ......................... */}

                                <Row className="mt-1">
                                    <Col span={24}>
                                        <Title level={4}>Member Fees Details</Title>
                                    </Col>
                                </Row>
                                {/*
                                //.......................

                                ..........

                                ..... */}
                                {/* member */}

                                <Row gutter={10}>
                                    <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="MemberId1"
                                            label="Member Id #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Member Id",
                                                },
                                            ]}

                                        >
                                            {/* {console.log("member id", member.memberId)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                placeholder="Member Id"
                                                autoComplete="off"
                                            //value={member.memberId}

                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={8} lg={12} md={16} sm={24} xs={24}>
                                        <Form.Item
                                            name="Member1"
                                            label="Member #"
                                            className="field-with-prefix"
                                        >
                                            {/* {console.log("member", member.member)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                //value={member.member}
                                                autoComplete="off"
                                                placeholder="Member"
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="City1"
                                            label="City #"
                                            className="field-with-prefix"
                                        >
                                            {/* {console.log("city", member.city)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="City"
                                                disabled={true}
                                            //value={member.city}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="Fees1"
                                            label="Fees #"
                                            className="field-with-prefix"
                                            rules={[
                                                // {
                                                //     // required: false,
                                                //     // message: "Fees is required",
                                                // },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Fees",
                                                },
                                            ]}
                                        >

                                            <Input

                                                autoComplete="off"
                                                placeholder="Fees"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="Charges1"
                                            label="Charges #"
                                            className="field-with-prefix"
                                            rules={[
                                                // {
                                                //     //required: true,
                                                //     // message: "Email is required",
                                                // },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid charges",
                                                },
                                            ]}
                                        >
                                            {/* {console.log("lateFees", member.lateFees)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="Charges"
                                            //value={member.lateFees}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <hr style={{ marginBottom: '20px' }} />
                                    </Col>

                                </Row>

                                {/* //////////////// */}


                                <Row gutter={10}>
                                    <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="MemberId2"
                                            label="Member Id #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Member Id",
                                                },
                                            ]}

                                        >
                                            {/* {console.log("member id", member.memberId)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                placeholder="Member Id"
                                                autoComplete="off"
                                            //value={member.memberId}

                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={8} lg={12} md={16} sm={24} xs={24}>
                                        <Form.Item
                                            name="Member2"
                                            label="Member #"
                                            className="field-with-prefix"
                                        >
                                            {/* {console.log("member", member.member)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                //value={member.member}
                                                autoComplete="off"
                                                placeholder="Member"
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="City2"
                                            label="City #"
                                            className="field-with-prefix"
                                        >
                                            {/* {console.log("city", member.city)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="City"
                                                disabled={true}
                                            //value={member.city}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="Fees2"
                                            label="Fees #"
                                            className="field-with-prefix"
                                            rules={[
                                                // {
                                                //     //required: true,
                                                //     // message: "Email is required",
                                                // },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Fees",
                                                },
                                            ]}
                                        >
                                            {/* {console.log("fees", member.fees)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                //value={member.fees}
                                                autoComplete="off"
                                                placeholder="Fees"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            name="Charges2"
                                            label="Charges #"
                                            className="field-with-prefix"
                                            rules={[
                                                // {
                                                //     //required: true,
                                                //     // message: "Email is required",
                                                // },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid charges",
                                                },
                                            ]}
                                        >
                                            {/* {console.log("lateFees", member.lateFees)} */}
                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="Charges"
                                            //value={member.lateFees}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <hr style={{ marginBottom: '20px' }} />
                                    </Col>

                                </Row>

                                {/* payment */}
                                <Row className="mt-1">
                                    <Col span={24}>
                                        <Title level={4}>Payment </Title>
                                    </Col>
                                </Row>

                                {/* <Row gutter={10}> */}
                                <Form.Item name="PaymentMethod" label="Payment Method #">
                                    <Radio.Group onChange={onChange} value={value} defaultValue="cash">
                                        <Radio value="cash"> Cash </Radio>
                                        <Radio value="cheque"> Cheque </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {/* </Row> */}

                                {/* Bank */}
                                {value === 'cheque' ?
                                    <>
                                        <Row gutter={10}>
                                            <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name="BankName"
                                                    label="Bank Name #"
                                                    className="field-with-prefix"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Bank Name is required",
                                                        },
                                                        {
                                                            pattern: /^([^0-9]*)$/,
                                                            message: "Please Insert Valid Bank Name",
                                                        },
                                                    ]}

                                                >
                                                    <Input
                                                        //addonBefore={prefixSelector}
                                                        autoComplete="off"
                                                        placeholder="Bank Name"
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name="BranchName"
                                                    label="Branch Name #"
                                                    className="field-with-prefix"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Branch Name is required",
                                                        },
                                                        {
                                                            pattern: /^([^0-9]*)$/,
                                                            message: "Please Insert Valid Branch Name",
                                                        },
                                                    ]}

                                                >
                                                    <Input
                                                        //addonBefore={prefixSelector}
                                                        autoComplete="off"
                                                        placeholder="Branch Name"
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name="ChequeNo"
                                                    label="Cheque/Draft #"
                                                    className="field-with-prefix"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "cheque No. is required",
                                                        },
                                                        {
                                                            pattern: /^(?:\d*)$/,
                                                            message: "Please Insert Valid Cheque/Draft Id",
                                                        },
                                                    ]}

                                                >
                                                    <Input
                                                        //addonBefore={prefixSelector}
                                                        autoComplete="off"
                                                        placeholder="Cheque / Draft"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name="ChequeDate"
                                                    label="Date #"
                                                    className="field-with-prefix"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Date is required",
                                                        }
                                                    ]}
                                                >
                                                    <DatePicker
                                                        placeholder="Cheque date"
                                                        style={{ width: "100%" }}
                                                        format="DD-MM-YYYY"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                    : ''}
                                {/* receipt */}
                                <Row gutter={10}>
                                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name="ReceiptNo"
                                            label="Receipt No #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Receipt No. is required",
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Receipt No.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="Receipt No"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name="ReceiptDate"
                                            label="Receipt Date #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Date is required",
                                                }
                                            ]}
                                        >

                                            <DatePicker
                                                placeholder="Joining date"
                                                style={{ width: "100%" }}
                                                format="DD-MM-YYYY"
                                            />

                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* success */}
                                <Row>
                                    <Col span={1} style={{ textAlign: "end" }}>
                                        <Form.Item className="submit">
                                            <Button
                                                size="large"
                                                className="success-btn "
                                                style={{ marginTop: '10px' }}
                                                // type="success"
                                                htmlType="submit"
                                            // loading={isLoading}
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Form>

                        </div>
                    </Col>
                </Row>
            </div>
        </SiteLayout>
    );
}

export default connect(mapState, mapDispatch)(AddFees);
