import axios from "axios";
import { history } from "./history";
import { message } from "antd";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL,
});

api.interceptors.request.use(
  function (config) {
    if (config.url !== "admin/login") {
      config.headers.Authorization = `${JSON.parse(
        localStorage.getItem("Bearer")
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.data.code === 401) {
      // message.error("401 Unauthorized Error");
      // localStorage.clear();
      // history.push("/");
    }

    return Promise.reject(error);
  }
);

export default api;
