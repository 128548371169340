import {
  ADD_MEMBER_LOADING,
  ADD_MEMBER_SUCCESS,
  MEMBER_STATUS,
  EDIT_MEMBER_LOADING,
  EDIT_MEMBER_SUCCESS,
  MEMBER_LIST,
  LOADER,
  MEMBER_DETAILS,
  INITIALIZE_STATE,
} from "../Action/ActionTypes";

const initialState = {
  isloading: true,
  MemberArray: [],
  MemberDetails: null,
  ResponseCode: 0,
  success: false,
  MmberStatus: null,
  successDetails: null,
  error: false,
  errorDetails: null,
  totalItems: 0, // Add totalItems in the initial state
};

export default function MemberReducer(state = initialState, action) {

  switch (action.type) {
    case ADD_MEMBER_LOADING:
      return { ...state, isloading: true };

    case EDIT_MEMBER_LOADING:
      return { ...state, isloading: true };

    // Add Success
    case ADD_MEMBER_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        error: false,
        errorDetails: null,
        successDetails: action.payload,
      };

    // Add Error
    // case ADD_MEMBER_ERROR:
    //   return {
    //     ...state,
    //     isloading: false,
    //     success: false,
    //     error: true,
    //     errorDetails: payload,
    //     successDetails: null,
    //   };

    // Edit Success
    case EDIT_MEMBER_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        error: false,
        errorDetails: null,
        successDetails: action.payload,
      };

    // Edit Error
    // case EDIT_MEMBER_ERROR:
    //   return {
    //     ...state,
    //     isloading: false,
    //     success: false,
    //     error: true,
    //     errorDetails: payload,
    //     successDetails: payload,
    //   };

    case MEMBER_LIST:
      return {
        ...state,
        MemberArray: action.payload,
        totalItems: action.totalItems, // Update totalItems in the reducer
      };

    case MEMBER_DETAILS: {
      return {
        ...state,
        MemberDetails: action.payload,
      };
    }
    
    case MEMBER_STATUS:
      return {
        ...state,
        MmberStatus: action.payload,
        // MmberStatus: true,
      };

    case LOADER:
      return {
        ...state,
        isloading: action.payload,
      };

    case INITIALIZE_STATE:
      return {
        ...state,
        successDetails: null,
        success: false,
      };

    default:
      return state;
  }
}
