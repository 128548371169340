import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, Navigate } from "react-router-dom";
import  "../style.module.scss";
import { Button, Form, Input, Layout } from "antd";
import { SiteConstants } from "../../../Config/SiteConstants";
import ReCAPTCHA from "react-google-recaptcha";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import mainLogo from "../../../assets/images/logo.png";
import { LoginCheck } from "../../../Action/AdminAuth";
import Footernew from '../../Reusable/Main-Layput/Footernew';
import Headernew from '../../Reusable/Main-Layput/Headernew';


const mapDispatch = (dispatch) => {
  return {
    LoginDispatch: (email, password, navigate, tokenRecaptcha) =>
      dispatch(LoginCheck(email, password, navigate, tokenRecaptcha)),
  };
};

const mapState = (props) => ({
  CheckLogin: props.AuthReducer.isLoggedInAdminState,
});

function Home(store) {
  // const [form] = Form.useForm();
  let navigate = useNavigate();
  const reCaptchaRef = useRef();
  const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  const { Footer } = Layout;

  const onFinish = () => {
    store.LoginDispatch(email, password, navigate, tokenRecaptcha);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(value) {
    console.log("value: ", value);
    setTokenRecaptcha(value);
  }
  return (
<>
<Headernew/>
 <section id="slider" className="slider-element dark swiper_wrapper slider-parallax min-vh-75">
    <div className="slider-inner">
      <div className="swiper swiper-parent">
        <div className="swiper-wrapper">
          <div className="swiper-slide dark">
            <div className="container">
              <div className="slider-caption">
                <div>
                  <h2 className="text-transform-none" data-animate="fadeInUp">Help the Homeless.</h2>
                  <a href="#" data-animate="fadeInUp" data-delay={400} className="button button-rounded button-large button-light shadow text-transform-none ls-0 ms-0 mt-4">Know More</a>
                </div>
              </div>
            </div>
            <div className="swiper-slide-bg" style={{background: 'linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.5)), url("demos/nonprofit/images/slider/1.jpg") no-repeat center center', backgroundSize: 'cover'}} />
          </div>
          <div className="swiper-slide dark">
            <div className="container">
              <div className="slider-caption">
                <div>
                  <h2 className="text-transform-none" data-animate="fadeInUp">Donate with Kindness.</h2>
                  <a href="#" data-animate="fadeInUp" data-delay={400} className="button button-rounded button-large button-light shadow text-transform-none ls-0 ms-0 mt-4">Know More</a>
                </div>
              </div>
            </div>
            <div className="swiper-slide-bg" style={{background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.8)), url("demos/nonprofit/images/slider/2.jpg") no-repeat center center', backgroundSize: 'cover'}} />
          </div>
        </div>
        <div className="swiper-navs">
          <div className="slider-arrow-left"><i className="bi-arrow-left" /></div>
          <div className="slider-arrow-right"><i className="bi-arrow-right" /></div>
        </div>
        <div className="swiper-scrollbar">
          <div className="swiper-scrollbar-drag">
            <div className="slide-number"><div className="slide-number-current" /><span>/</span><div className="slide-number-total" /></div></div>
        </div>
      </div>
    </div>
  </section>
  <section id="content">
    <div className="content-wrap py-0" style={{ overflow: "visible" }}>
    <svg
      viewBox="0 0 1382 58"
      width="100%"
      height={60}
      preserveAspectRatio="none"
      style={{ position: "absolute", top: "-58px", left: 0, zIndex: 1 }}
    >
      <path style={{ fill: "#FFF" }} d="M1.52.62s802.13,127,1380,0v56H.51Z" />
    </svg>

    <div className="container">
      <div className="slider-feature w-100">
        <div className="row justify-content-center">
          <div className="col-md-3 px-1">
            <a href="/about-us"
              className="card text-center border-start-0 border-end-0 border-top-0 border-bottom border-bottom shadow py-3 rounded-0 fw-semibold text-uppercase ls-1">
              <div className="card-body">
                <i className="bi-text-center" />
                View Our Mission
              </div>
            </a>
          </div>

          <div className="col-md-3 px-1">
            <a
              href="/about-us"
              className="card text-center border-start-0 border-end-0 border-top-0 border-bottom border-bottom shadow py-3 rounded-0 fw-semibold text-uppercase ls-1"
            >
              <div className="card-body">
                <i className="bi-envelope" />
                Become a Member
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    
<div
  className="section mt-3"
  style={{
    background:
      '#FFF url("demos/nonprofit/images/others/1.jpg") no-repeat 100% 50% / auto 100%'
  }}
>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-7 text-center">
        <div className="heading-block border-bottom-0 mb-4">
          <h2 className="mb-4 text-transform-none">Shri Samasta Dashashrimali Sukhadiya Suraksha Trust</h2>
        </div>
        <div className="svg-line mb-4">
          <img
            src="demos/nonprofit/images/divider-1.svg"
            alt="svg divider"
            height={20}
          />
        </div>

      </div>
    </div>
    <div className="row gx-5 mt-5 col-mb-50 mb-0">
    <div className="col-md-3">
        <div className="feature-box flex-column ps-0">
          <div className="fbox-media position-relative">
            <img
              src="demos/nonprofit/images/icons/volunteer.svg"
              alt="Featured Icon"
              width={60}
              className="mb-3"
            />
          </div>
          <div className="fbox-content px-0">
            <h3 className="text-transform-none ls-0">
              <a href="#" className="text-dark">
                Become a Member.
              </a>
            </h3>
            <p>
              Submit a completed application form with details of two nominees.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="feature-box flex-column ps-0">
          <div className="fbox-media position-relative">
            <img
              src="demos/nonprofit/images/icons/donate.svg"
              alt="Featured Icon"
              width={60}
              className="mb-3"
            />
          </div>
          <div className="fbox-content px-0">
            <h3 className="text-transform-none ls-0">
              <a href="#" className="text-dark">
                Give Contribution.
              </a>
            </h3>
            <p>
            DFC contributions can be made via cash, cheque, or DD. Late payments must be in cash.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="feature-box flex-column ps-0">
          <div className="fbox-media position-relative">
            <img
              src="demos/nonprofit/images/icons/help-child.svg"
              alt="Featured Icon"
              width={60}
              className="mb-3"
            />
          </div>
          <div className="fbox-content px-0">
            <h3 className="text-transform-none ls-0">
              <a href="#" className="text-dark">
                Help families.
              </a>
            </h3>
            <p>
            Act as a financial custodian for member security.
          </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="clear" />
  <div className="container-fluid my-5">
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center counter-section position-relative py-5"
      style={{
        background:
          'url("demos/nonprofit/images/world-map.png") no-repeat center center/ contain'
      }}
    >
      <div className="mx-auto text-center" style={{ maxWidth: 1000 }}>
        <h3>
          Our mission is to provide financial assistance to families at the time of a member's death.
        </h3>
      </div>
      <div className="row align-items-stretch m-0 w-100">

        <div className="col-lg-4 col-sm-6 text-center mt-5">
          <img
            src="demos/nonprofit/images/icons/volunteers.svg"
            alt="Counter Icon"
            width={70}
            className="mb-4"
          />
          <div className="counter font-secondary">
            <span
              data-from={100}
              data-to={2500}
              data-refresh-interval={50}
              data-speed={2200}
              data-comma="true"
            />
          </div>
          <h5 className="text-transform-none ls-0 mt-0">
            Medical
          </h5>
        </div>
        <div className="col-lg-4 col-sm-6 text-center mt-5">
          <img
            src="demos/nonprofit/images/icons/educated.svg"
            alt="Counter Icon"
            width={70}
            className="mb-4"
          />
          <div className="counter font-secondary">
            <span
              data-from={100}
              data-to={17408}
              data-refresh-interval={25}
              data-speed={2300}
              data-comma="true"
            />
          </div>
          <h5 className="text-transform-none ls-0 mt-0">
            Education
          </h5>
        </div>
        <div className="col-lg-4 col-sm-6 text-center mt-5">
          <img
            src="demos/nonprofit/images/icons/shelter.svg"
            alt="Counter Icon"
            width={70}
            className="mb-4"
          />
          <div className="counter font-secondary">
            <span
              data-from={150}
              data-to={13413}
              data-refresh-interval={50}
              data-speed={2100}
              data-comma="true"
            />
          </div>
          <h5 className="text-transform-none ls-0 mt-0">
            Social Walfare
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div className="section bg-transparent" style={{padding: '80px 0'}}>
  <div className="section m-0 bg-transparent pt-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3 className="mb-2">Our Mission</h3>
                  <div className="svg-line mb-2">
                    <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                  </div>
                  <div className="row mission-goals gutter-30 mb-0">
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/home.svg" alt="Featured Icon 1" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>To enhance the religious and organizational sense among the Samasta Dashashrimali Sukhadiya community.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/health.svg" alt="Featured Icon 2" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Provide financial assistance to families at the time of a member's death.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/education.svg" alt="Featured Icon 3" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Offer support during medical emergencies to members.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/animal.svg" alt="Featured Icon 4" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Work towards the comprehensive development and interest of members.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/environmental.svg" alt="Featured Icon 5" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Improve physical health through seminars, yoga, exercises, and promote a healthy society.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/food.svg" alt="Featured Icon 6" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Act as a financial custodian for member security.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/food.svg" alt="Featured Icon 6" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Organize educational, medical, and social welfare camps.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/food.svg" alt="Featured Icon 6" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Organize educational, medical, and social welfare camps.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
</div>
<div className="line line-sm my-0" />
  
      </div>
    </section>
      <Footernew/>
  </>
  );
}

export default connect(mapState, mapDispatch)(Home);
