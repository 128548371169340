import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "../Login/login.scss";
import { Button, Form, Input, Layout } from "antd";
import { SiteConstants } from "../../../Config/SiteConstants";
import ReCAPTCHA from "react-google-recaptcha";
import { NumberOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

import mainLogo from "../../../assets/images/logo.png";
import { PaymentCheckAction } from "../../../Action/PaymentCheck";

const mapDispatch = (dispatch) => {
    return {
      PaymentDispatch: (insurance, navigate, tokenRecaptcha) =>
        dispatch(PaymentCheckAction(insurance, navigate, tokenRecaptcha)),
    };
  };

  const mapState = (props) => ({
    Loader: props?.PaymentCheckReducer?.loading,
});

const PaymentCheck = (store) => {
  
  let navigate = useNavigate();
  const reCaptchaRef = useRef();
  const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  const [insurance, setinsurance] = useState("");
  const { Footer } = Layout;

  const onFinish = () => {
    store.PaymentDispatch(insurance, navigate, tokenRecaptcha);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(value) {
    console.log("value: ", value);
    setTokenRecaptcha(value);
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Payment Check - ${SiteConstants.site_name} `}</title>
      </Helmet>
      {store?.Loader === true && <Loader />}
      <div className="divBg">
        <div className="logoDiv">
          <img src={mainLogo} alt="logo" style={{ width: "8%" }} />
        </div>

        <div className="center">
          <div className="containerLogin loginform">
            {/* <div className="text">Login</div> */}

            <h2>
              <strong>DFC Payment</strong>
            </h2>

            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="data">
                <label>
                  <b className="asterisk">*</b> Insurance Number
                </label>

                <Form.Item
                  type="text"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Insurance Number!",
                    },
                  ]}
                >
                  <Input
                    name="text"
                    onChange={(e) => setinsurance(e.target.value)}
                    placeholder="Insurance Number"
                    prefix={<NumberOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="data">
                <Form.Item
                  name="reCAPTCHA"
                  rules={[
                    {
                      required: true,
                      message: "reCAPTCHA is required",
                    },
                  ]}
                >
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={SiteConstants.googleRecaptchaKey.siteKey}
                    size="normal"
                    onChange={onChange}
                    onExpired={(e) => setTokenRecaptcha("")}
                    onErrored={(e) => setTokenRecaptcha("")}
                  />
                </Form.Item>
              </div>

             
              <div className="inner"></div>

              <Button type="primary" htmlType="submit" className="button-login">
                Submit
              </Button>                  
              {/* </div> */}
            </Form>
          </div>
        </div>


        <Footer
          style={{
            textAlign: "center",
            position: "fixed",
            bottom: "0px",
            width: "100%",
            color: "white",
            backgroundColor: "transparent"
          }}
        >
          <div style={{ margin: "10px" }}>
            <Link className="link" to="/terms-conditions">
              Terms & Conditions
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/privacy-policy">
              Privacy Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/refund-policy">
              Refund Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/contact-us">
              Contact Us
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/about-us">
              About Us
            </Link>
          </div>
          © {new Date().getFullYear()} {SiteConstants.site_name} · All Right
          Reserved
        </Footer>
      </div>
    </React.Fragment>
  );
};

export default connect(mapState , mapDispatch)(PaymentCheck);

