import React from 'react'
import { SiteConstants } from "../../../Config/SiteConstants";

function Footernew() {



    return (
        <footer id="footer" style={{ backgroundColor: "#002D40" }}>

        <div id="copyrights" className="bg-color">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6">
              © {new Date().getFullYear()} {SiteConstants.site_name} · All Right
                            Reserved
                <br />
                <div className="copyright-links">
                  <a href="/terms-conditions">Terms of Use</a> / <a href="/privacy-policy">Privacy Policy</a> / <a href="/refund-policy">Refund Policy</a>
                </div>
              </div>
              <div className="col-md-6 d-md-flex flex-md-column align-items-md-end mt-4 mt-md-0">
                <div className="copyrights-menu copyright-links">
                  <a href="/about-us">About</a>/<a href="/contact-us">Contact</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      
    )
}

export default Footernew
