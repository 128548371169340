import {
    GET_SETTING,
    SETTING_LOADER,
    SETTING_FAIL,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";


// fetch setting
export const getSetting = (payload) => {

    return (dispatch) => {
        dispatch(loader(true));
        try {

            api.get("admin/setting", payload).then(
                (response) => {
                    if (response?.status === 200) {
                        let data = response?.data?.message
                        dispatch({
                            type: GET_SETTING,
                            payload: data,
                        });
                    }
                    else {
                        message.error(response?.data?.message);
                    }
                },
                function (error) {
                    dispatch({
                        type: SETTING_FAIL,
                    });

                }
            );
        } catch (err) {
            console.log("catch err", err);
        }
    };
};

export const loader = (para) => {
    return {
        type: SETTING_LOADER,
        payload: para,
    };
};