import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCESqmoGhBUlXOn7IXYZYyRkswP9UoWTI0",
  authDomain: "ssdsst-ead06.firebaseapp.com",
  projectId: "ssdsst-ead06",
  storageBucket: "ssdsst-ead06.appspot.com",
  messagingSenderId: "1076731268731",
  appId: "1:1076731268731:web:6836792b6dac408306dcde",
  measurementId: "G-L93T8216WX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const firebase = initializeApp(firebaseConfig);
export { auth, RecaptchaVerifier };
