
import React from 'react';

const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
    <div style={{ border: '5px solid #f3f3f3', borderRadius: '50%', borderTop: '5px solid #3498db', width: '50px', height: '50px', animation: 'spin 1s linear infinite' }}>
    </div>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
  </div>
);

export default Loader;
