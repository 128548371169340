import {
    ADD_SETTING_LOADER,
    ADD_SETTING,
    NULL_SETTING,
} from "../Action/ActionTypes";

const initialState = {
    success: false,
    error: false,
    msg: null,
    settingloading: false,
};

export default function AddSettingReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_SETTING:

            return {
                ...state,
                success: true,
                msg: payload,
                settingloading: false,
            };

        case NULL_SETTING:

            return {
                ...state,
                success: false,
                msg: payload,
                settingloading: false,
            };

        case ADD_SETTING_LOADER:

            return {
                ...state,
                settingloading: payload,
            };

        default:

            return state;
    }

}
