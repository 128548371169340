import {
  TRANSACTION_LIST,
  TRANSACTION_LOADING,
  INITIALIZE_STATE,
  LOADER,
} from "../Action/ActionTypes";

const initialState = {
  isloading: true,
  TransactionList: [],
  TransactionStatus: "",
  TransactionDetails: null,
  success: false,
  error: false,
  errorDetails: null,
};

export default function TransactionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TRANSACTION_LOADING:
      return { ...state, isloading: true };

    case TRANSACTION_LIST:
      return {
        ...state,
        isloading: false,
        success: true,
        error: false,
        errorDetails: null,
        TransactionList: payload,
      };

    case LOADER:
      return {
        ...state,
        isloading: payload,
      };

    case INITIALIZE_STATE:
      return {
        ...state,
        successDetails: null,
        success: false,
      };

    default:
      return state;
  }
}
