let baseUrl =  process.env.REACT_APP_PUBLIC_URL;
let apiURL =  process.env.REACT_APP_SERVER_API_URL;
const googleAnalyticsCode = "";

// if (process.env.NODE_ENV === "production") {
//   baseUrl = "https://ssdsst.com/";
//   apiURL = "https://api.ssdsst.com";
// }

let AppConfig = {
  appName: "શ્રી સમસ્ત દશા શ્રી માળી સુખડીયા સુરક્ષા ટ્રસ્ટ, અમદાવાદ",
  googleAnalyticsCode,
  paginationLimit: 10,
  maxUserImageAllowedSize: 2,
  maxUserFileAllowedSize: 10,
  googleRecaptchaKey: {
    siteKey: "6Lc7-7gaAAAAAKoMYzHxJXJX-TTDU6vvCA257e_E",
    secretKey: "6Lc7-7gaAAAAAOoaXUNcY2NZIZcLzTlJQPCMUhfx",
  },
  baseUrl,
  apiURL,
  tokenReceivingAddress: "",
  tokenSymbol: "",
  contractAddress: "",
};
export default AppConfig;
