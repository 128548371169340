import { combineReducers } from "redux";
import AuthReducer from "../Reducer/AuthReducer";
import SiteSettingReducer from "../Reducer/SiteSettingReducer";
import MemberReducer from "./MemberReducer";
import FeesReducer from "./FeesReducer";
import DeathReducer from "./DeathReducer";
import AddDeathReducer from "./AddDeathReducer";
import SettingReducer from "./SettingReducer";
import AddSettingReducer from "./AddSettingReducer";
import PaymentCheckReducer from "./PaymentCheckReducer";
import TransactionReducer from "./TransactionReducer";
import DashboardReducer from "./DashboardReducer";
import UserTransactionReducer from "./UserTransactionReducer";

export default combineReducers({
  AuthReducer,
  SiteSettingReducer,
  MemberReducer,
  FeesReducer,
  DeathReducer,
  AddDeathReducer,
  SettingReducer,
  AddSettingReducer,
  PaymentCheckReducer,
  TransactionReducer,
  DashboardReducer,
  UserTransactionReducer,
});
