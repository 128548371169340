import {
  TRANSACTION_LIST,
  TRANSACTION_LOADING,
  INITIALIZE_STATE,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

// Select List
export const TransactionListAction = () => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      api({
        url: "admin/transactions",
        method: "get",
      }).then(
        (response) => {
          let data = response.data.data.docs;
          let responseStatus = response.data.code;

          if (responseStatus === 200) {
            dispatch({
              type: TRANSACTION_LIST,
              payload: data,
            });
            dispatch(loader(false));
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

export const initializeState = (para) => {
  return (dispatch) => {
    dispatch({
      type: INITIALIZE_STATE,
    });
  };
};

function errorMessageConfig(dispatch) {
  dispatch(loader(false));
  message.error("Something Went Wrong");
}

export const loader = (para) => {
  return {
    type: TRANSACTION_LOADING,
    payload: para,
  };
};
