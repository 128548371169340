// Admin Login
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const LOADER = "LOADER";

// User Login
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

// Setting
export const UPDATE_SETTING_ERROR = "UPDATE_SETTING_ERROR";
export const UPDATE_SETTING = "UPDATE_SETTING";

// Dashboard
export const COUNT_USERS = "COUNT_USERS";

// Site Setting
export const COLLAPSED_MENU = "COLLAPSED_MENU";

export const MEMBER_ADD = "MEMBER_ADD";
export const MEMBER_LIST = "MEMBER_LIST";
export const MEMBER_DETAILS = "MEMBER_DETAILS";

export const ADD_MEMBER_LOADING = "ADD_MEMBER_LOADING";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_ERROR = "ADD_MEMBER_ERROR";

export const EDIT_MEMBER_LOADING = "EDIT_MEMBER_LOADING";
export const EDIT_MEMBER_ERROR = " EDIT_MEMBER_ERROR";
export const EDIT_MEMBER_SUCCESS = "EDIT_MEMBER_SUCCESS";

export const INITIALIZE_STATE = "INITIALIZE_STATE";

// .............. start deep .................
export const DFC_FEES_ADD = "DFC_FEES_ADD";
export const FIND_MEMBER = "FIND_MEMBER";
export const FIND_MEMBER_NULL = "FIND_MEMBER_NULL";
export const ADD_DEATH_MEMBER = "ADD_DEATH_MEMBER";
export const GET_DEATH_MEMBERS = "GET_DEATH_MEMBERS";
export const GET_DFC_LIST = "GET_DFC_LIST";
export const GET_DFC_DETAIL = "GET_DFC_DETAIL";
export const NULL_DEATH_MEMBER = "NULL_DEATH_MEMBER";
export const SUBMIT_LOADER = "SUBMIT_LOADER";
export const SETTING_LOADER = "SETTING_LOADER";
export const GET_SETTING = "GET_SETTING";
export const ADD_SETTING_LOADER = "ADD_SETTING_LOADER";
export const ADD_SETTING = "ADD_SETTING";
export const NULL_SETTING = "NULL_SETTING";
export const SETTING_FAIL = "SETTING_FAIL";
export const MEMBER_STATUS = "MEMBER_STATUS";
export const FIND_DEATH = "FIND_DEATH";
export const PAYMENT_CHECK = "PAYMENT_CHECK";
export const PAYMENT_CHECK_LOADING = "PAYMENT_CHECK_LOADING";
export const TRANSACTION_LIST = "TRANSACTION_LIST";
export const TRANSACTION_LOADING = "TRANSACTION_LOADING";
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const FETCH_DASHBOARD_NULL = "FETCH_DASHBOARD_NULL";
export const DASHBOARD_LOADING = "DASHBOARD_LOADING";

// ................. end deep ...............p
