/* eslint-disable func-names */
import React, { useEffect } from 'react'

function Headernew() {


    return (
        <>
          <header
  id="header"
  className="header-size-sm border-bottom-0"
  data-sticky-shrink="false"
>
  <div id="header-wrap">
    <div className="container">
      <div className="header-row justify-content-lg-between">
        <div id="logo" className="me-lg-5">
          <a href="/">
            <img
              className="logo-default"
              srcSet="images/logo.png"
              src="demos/business/images/logo@2x.png"
              alt="Canvas Logo"
            />
            <img
              className="logo-dark"
              srcSet="demos/business/images/logo-dark.png, demos/business/images/logo-dark@2x.png 2x"
              src="demos/business/images/logo-dark@2x.png"
              alt="Canvas Logo"
            />
          </a>
        </div>
        <div className="header-misc">
          <a
            href="/user-login"
            className="button button-rounded button-light"
          >
            <div>Pay DFC</div>
          </a>
        </div>
        <div className="primary-menu-trigger">
          <button
            className="cnvs-hamburger"
            type="button"
            title="Open Mobile Menu"
          >
            <span className="cnvs-hamburger-box">
              <span className="cnvs-hamburger-inner" />
            </span>
          </button>
        </div>
        <nav className="primary-menu with-arrows me-lg-auto">
          <ul className="menu-container align-self-start">
            <li className="menu-item">
              <span className="menu-bg col-auto align-self-start d-flex" />
            </li>
            <li className="menu-item current">
              <a className="menu-link" href="/">
                <div>Home</div>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" href="/about-us">
                <div>About Us</div>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" href="/contact-us">
                <div>Contact Us</div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div className="header-wrap-clone" />
</header>
        </>
    )
}

export default Headernew

