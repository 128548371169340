import { PAYMENT_CHECK, PAYMENT_CHECK_LOADING } from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";
import * as _ from "lodash";

function openPost(url, params) {
  const formElement = document.createElement("form");
  formElement.setAttribute("method", "post");
  formElement.setAttribute("action", url);
  formElement.setAttribute("target", "_parent");
  _.forEach(params, function (value, key) {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("name", key);
    hiddenField.setAttribute("value", value);
    formElement.appendChild(hiddenField);
  });
  document.body.appendChild(formElement);
  formElement.submit();
}

export const PaymentCheckAction = (insurance, navigate, tokenRecaptcha) => {
  return (dispatch) => {
    dispatch(loader(true));
    const credentials = {
      // insurance,
      reCAPTCHA: tokenRecaptcha,
    };

    try {
      api
        .post(
          `user/insurance/payment?insuranceNumber=${insurance}`,
          credentials
        )
        .then(
          (response) => {
            if (response.status === 200) {
              dispatch({
                type: PAYMENT_CHECK,
                payload: { isPaymentCheckState: true },
              });
              const params = {
                key: process.env.REACT_APP_PAYMENT_KEY,
                firstname: response.data.data.firstname,
                email: response.data.data.email,
                phone: "",
                productinfo: process.env.REACT_APP_PAYMENT_PRODUCTINFO,
                udf1: response.data.data.insurancenumber,
                surl: `https://ssdsst.com/success.php`,
                furl: `https://ssdsst.com/error.php`,
                txnid: response.data.data.txnid,
                hash: response.data.data.hash,
                service_provider:
                  process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER,
                salt: process.env.REACT_APP_PAYMENT_SALT,
                amount: response.data.data.amount,
              };
              console.log(
                "process.env.REACT_APP_PAYMENT_SALT: ",
                process.env.REACT_APP_PAYMENT_SALT
              );
              console.log("params: ", params);
              // this.openPost('https://test.payu.in/_payment', params)
              openPost("https://test.payu.in/_payment", params);
            } else {
              errorMessageConfig();
            }
            dispatch(loader(false));
          },
          function (error) {
            dispatch(loader(false));
            if (error.response) {
              message.error(error.response.data.message);
            } else {
              errorMessageConfig();
            }
          }
        );
    } catch (err) {
      console.log("err: ", err);
      errorMessageConfig();
    }
  };
};

function errorMessageConfig() {
  message.error("Something Went Wrong");
}

export const loader = (para) => {
  return {
    type: PAYMENT_CHECK_LOADING,
    payload: para,
  };
};
