import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, Navigate } from "react-router-dom";
import { Form, Input, Layout, message } from "antd";
import { SiteConstants } from "../../../Config/SiteConstants";
import { PhoneOutlined, LockOutlined, EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import mainLogo from "../../../assets/images/logo.png";
import { SendOtp, VerifyOtp } from "../../../Action/UserAuth";
import { auth } from '../../../Config/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const mapDispatch = (dispatch) => {
  return {
    SendOtpDispatch: (mobileNumber) => dispatch(SendOtp(mobileNumber)),
    LoginDispatch: (mobileNumber, otp, navigate) =>
      dispatch(VerifyOtp(mobileNumber, otp, navigate)),
  };
};

const mapState = (props) => ({
  CheckLogin: props.AuthReducer.isLoggedInAdminState,
});



function Success(store) {

  const { Footer } = Layout;

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login - ${SiteConstants.site_name}`}</title>
      </Helmet>
      {store.CheckLogin === true && <Navigate to="/dashboard" />}

      <div className="divBg">
        <div className="logoDiv">
          <img src={mainLogo} alt="logo" style={{ width: "8%" }} />
        </div>

        <div className="center">
          <div className="containerLogin loginform">
          <div className="payment-success-container">
      <div className="payment-success-card">
        <h1>Payment Successful!</h1>
        <p>Thank you for your contribution.</p>
        <Link className="link" to="/">
          <button className="continue-shopping-btn">Go to Homepage</button>
        </Link>
      </div>
    </div>
           
          </div>
        </div>

        <Footer
          style={{
            textAlign: "center",
            position: "fixed",
            bottom: "0px",
            width: "100%",
            color: "white",
            backgroundColor: "transparent",
          }}
        >
          <div style={{ margin: "10px" }}>
            <Link className="link" to="/terms-conditions">
              Terms & Conditions
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/privacy-policy">
              Privacy Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/refund-policy">
              Refund Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/contact-us">
              Contact Us
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="link" to="/about-us">
              About Us
            </Link>
          </div>
          © {new Date().getFullYear()} {SiteConstants.site_name} · All Right
          Reserved
        </Footer>
      </div>
    </React.Fragment>
  );
}

export default Success;