import {
    FETCH_DASHBOARD,
    DASHBOARD_LOADING
} from "../Action/ActionTypes";

const initialState = {
    success: false,
    dashboardData: null,
    error: false,
    loading: false,
};

export default function DashboardReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case FETCH_DASHBOARD:
            return {
                ...state,
                success: true,
                dashboardData: payload,
                loading: false,
            };

        case DASHBOARD_LOADING:
            return {
                ...state,
                loading: payload,
            };

        default:
            return state;
    }

}
