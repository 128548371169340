import { PAYMENT_CHECK, PAYMENT_CHECK_LOADING } from "../Action/ActionTypes";

const initialState = {
  success: false,
  checkpayment: null,
  error: false,
  loading: false,
};

export default function PaymentCheckReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PAYMENT_CHECK:
      // console.log("add reducer--------------");
      return {
        ...state,
        success: true,
        checkpayment: payload,
        loading: false,
      };

    case PAYMENT_CHECK_LOADING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
}
