import { COLLAPSED_MENU } from "../Action/ActionTypes";

const initialState = {
  isCollapsedMenu: null,
};

export default function SiteSettingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case COLLAPSED_MENU:
      return { isCollapsedMenu: payload };

    default:
      return state;
  }
}
