import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Row,
  Col,
  Descriptions,
  Badge,
  Skeleton,
  Radio,
  message,
  Spin,
} from "antd";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { SiteConstants } from "../../Config/SiteConstants";
import { getDFCDetail } from "../../Action/Death";
import api from "../../Config/Api";
import Loader from "../Reusable/Loader/Loader";

const mapState = (props) => ({
  DFCDetail: props?.DeathReducer?.dfcDetailPayload,
  DFC: props?.DeathReducer?.dfcDetailSuccess,
  SelectLoader: props?.DeathReducer?.isloading,
});

const mapDispatch = (dispatch) => {
  return {
    GetDFCDetail: (id) => dispatch(getDFCDetail(id)),
  };
};

function Dfcdetail(store) {
  let navigate = useNavigate();
  const { id } = useParams();
  let [DeathArray, setDeathArray] = useState([]);

  const data = DeathArray[0];

  const initialStatus = data?.status;
  const [status, setStatus] = useState(initialStatus);
  const [loader, setloader] = useState(false);

  // Handle change event of the radio buttons
  const handleStatusChange = async (e) => {
    console.log("e.target.value: ", e.target.value);
    const newStatus = e.target.value;
    setStatus(newStatus);
    try {
      setloader(true);
      await api
        .put("admin/users/dfc/update-status", {
          dfcId: id,
          status: newStatus,
        })
        .then((response) => {
          if (response?.status === 200) {
            setloader(false);
            message.success(response?.data?.message);
          } else {
            message.error(response?.data?.message);
          }
        });
    } catch (err) {
      console.log("catch err", err);
    }
  };

  let { NA } = SiteConstants;

  useEffect(() => {
    store.GetDFCDetail(id);
  }, [id]);

  useEffect(() => {
    if (store?.DFCDetail) {
      setDeathArray(store?.DFCDetail);
      setStatus(store?.DFCDetail[0].status);
    }
  }, [store?.DFCDetail]);

  return (
    <SiteLayout>
      {loader === true && <Loader />}
      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => navigate(-1)}
              title={
                <>
                  {store.SelectLoader ? (
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      size="small"
                      style={{ margin: "0 8px", color: "black" }}
                    />
                  ) : (
                    ` DFC-${data?.dfcNumber} Detail`
                  )}
                </>
              }
            ></PageHeader>
          </Col>
        </Row>
      </div>

      <div>
        <Row>
          <Col xs={24} xl={24}>
            <Card>
              <Skeleton
                active
                loading={store.SelectLoader}
                paragraph={{ rows: 25 }}
              >
                {data?.deathMemberDetails.map((detail, index) => {
                  return (
                    <>
                      <Descriptions
                        title={`Member ${index + 1} - ${detail.firstname} ${
                          detail.middlename
                        } ${detail.lastname}`}
                        bordered
                      >
                        <Descriptions.Item label="Member Id" span={2}>
                          <Badge
                            status="processing"
                            text={detail?.memberId || NA}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label="DFC Location" span={2}>
                          <Badge
                            status="processing"
                            text={detail?.deathCity || NA}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label="DFC Date" span={2}>
                          <Badge
                            status="processing"
                            text={
                              moment(new Date(detail?.DeathOnUTC)).format(
                                "DD/MM/YYYY"
                              ) || NA
                            }
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label="Inform Date" span={4}>
                          <Badge
                            status="processing"
                            text={
                              moment(new Date(detail?.InformOnUTC)).format(
                                "DD/MM/YYYY"
                              ) || NA
                            }
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label="Payable Amount" span={2}>
                          <Badge
                            status="processing"
                            text={
                              detail?.payableamount.toLocaleString("en-IN", {
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency: "INR",
                              }) || NA
                            }
                          />
                        </Descriptions.Item>
                      </Descriptions>
                      <br />
                    </>
                  );
                })}

                <Descriptions title="DFC information" bordered>
                  <Descriptions.Item label="Number Of Users" span={2}>
                    <Badge
                      status="processing"
                      text={data?.numberOfUsers || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Create Date" span={2}>
                    <Badge
                      status="processing"
                      text={
                        moment(new Date(data?.createdDate)).format(
                          "DD/MM/YYYY"
                        ) || NA
                      }
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Payable Total Amount" span={4}>
                    <Badge
                      status="processing"
                      text={
                        data?.payableTotalAmount.toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: "INR",
                        }) || NA
                      }
                    />
                  </Descriptions.Item>
                </Descriptions>
                <br />
                <Descriptions bordered>
                  <Descriptions.Item label="Status" span={4}>
                    <Radio.Group onChange={handleStatusChange} value={status}>
                      {status !== "ACTIVE" && status !== "ON_HOLD" && (
                        <Radio value="PENDING">Pending</Radio>
                      )}
                      {status !== "PENDING" && (
                        <Radio value="ON_HOLD">On Hold</Radio>
                      )}
                      <Radio value="ACTIVE">Active</Radio>
                    </Radio.Group>
                  </Descriptions.Item>
                </Descriptions>
                <br />
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </div>

      <br />
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(Dfcdetail);
