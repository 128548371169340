import {
  FIND_MEMBER,
  FIND_MEMBER_NULL,
  GET_DEATH_MEMBERS,
  GET_DFC_LIST,
  FIND_DEATH,
  LOADER,
  INITIALIZE_STATE,
  GET_DFC_DETAIL,
} from "../Action/ActionTypes";

const initialState = {
  success: false,
  deathmember: [],
  dfc: [],
  dfcDetail: [],
  deathbyid: null,
  deathList: null,
  dfcList: null,
  dfcDetailPayload: null,
  dfcDetailSuccess: false,
  deathListSuccess: false,
  dfcListSuccess: false,
  successDetails: null,
  error: false,
  isloading: false,
};

export default function DeathReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FIND_MEMBER:
      return {
        ...state,
        success: true,
        deathmember: payload,
        isloading: false,
      };
    case FIND_MEMBER_NULL:
      return {
        ...state,
        success: false,
        isloading: false,
        deathmember: [],
      };

    case GET_DEATH_MEMBERS:
      return {
        ...state,
        isloading: false,
        deathmember: [],
        deathListSuccess: true,
        deathList: payload,
      };

    case GET_DFC_LIST:
      return {
        ...state,
        isloading: false,
        dfc: [],
        dfcListSuccess: true,
        dfcList: payload,
      };

    case GET_DFC_DETAIL:
      return {
        ...state,
        // isloading: false,
        dfcDetail: [],
        dfcDetailSuccess: true,
        dfcDetailPayload: payload,
      };

    case FIND_DEATH:
      return {
        ...state,
        isloading: false,
        deathmember: [],
        deathbyid: payload,
      };

    case LOADER:
      return {
        ...state,
        isloading: payload,
      };

    case INITIALIZE_STATE:
      return {
        success: false,
        deathmember: [],
        isloading: false,
      };

    default:
      return state;
  }
}
