import SiteLayout from "../Reusable/Layout/SiteLayout"
import React, { useState, useRef, useEffect } from "react";
import { Table, message, Space, Button, Input, Tooltip, Switch, Popconfirm, Tag } from "antd";
import { Link } from "react-router-dom";
import Loader from "../../Component/Reusable/Loader/Loader";
import moment from 'moment';

import {
  EyeOutlined,
  SearchOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import { connect, useSelector, useDispatch } from "react-redux";
import { TransactionListAction } from "../../Action/Transaction";

import "../Members/userstyle.scss";
import "../Members/style.css";

const Transactions = () => {

    const TransactionList = useSelector(state => state?.TransactionReducer?.TransactionList);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(TransactionListAction());
    }, []);

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div
            style={{
              padding: 8,
            }}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${title}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
            //   onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: "block",
              }}
            />
            <Space>
              <Button
                type="primary"
                // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 112,
                }}
              >
                Search
              </Button>
              <Button
                // onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 112,
                }}
              >
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? "#1890ff" : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            ?.toString()
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },

        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            text
          ),
      });

    let dataSource =
    TransactionList &&
    TransactionList?.map((data, index) => (
      {
        key: index,
        id: data?._id,
        userEmail: data?.userId.email,
        amount: data?.amount,
        lateFees: data?.lateFees,
        totalFee: data?.amount + data?.lateFees,
        paymentMethod: data?.paymentMethod,
        paymentDate: moment(data?.paymentDate).format("DD MMMM YYYY"),
        status: data?.status,
      }));

      const columns = [
        {
          title: "User Email",
          dataIndex: "userEmail",
          key: "userEmail",
          ...getColumnSearchProps("userEmail", "User Email"),
        },
    
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          ...getColumnSearchProps("amount", "Amount"),
        },
        {
          title: "Late Fees",
          dataIndex: "lateFees",
          key: "lateFees",
          ...getColumnSearchProps("lateFees", "Late Fees"),
        },

        {
          title: "Total Fees",
          dataIndex: "totalFee",
          key: "totalFee",
          ...getColumnSearchProps("totalFee", "Total Fees"),
        },
    
        {
          title: "Payment Method",
          dataIndex: "paymentMethod",
          key: "paymentMethod",
          ...getColumnSearchProps("paymentMethod", "Payment Method"),
        },
    
        {
          title: "Payment Date",
          dataIndex: "paymentDate",
          key: "paymentDate",
          ...getColumnSearchProps("paymentDate", "Payment Date"),
        },
        {
          title: "Status",
          dataIndex: "status",
        //   render: (text, record) => (
        //     <Popconfirm
        //       title={`Are you sure you want to  ${!record.status ? "Active" : "Inactive"}  this user?`}
        //     //   onConfirm={() => handleMemberStatus(!record.status, record.id)}
        //     >
        //       <Space size="middle">
        //         <Tooltip title={record.status ? "ACTIVE" : "INACTIVE"}>
        //           <Switch
        //             key={record.id}
        //             checked={record.status && true}
        //           />
        //         </Tooltip>
        //       </Space>
        //     </Popconfirm>
        //   ),
        render: (text, row) => {
            return(
            <Space size="middle">
              <Tag
                color={
                  (["SUCCESS", "success"].includes(row?.status) && "blue") ||
                  (["FAILED", "failed"].includes(row?.status) && "red")
                }
              >
                {row?.status}
              </Tag>
            </Space>
          )},
        },
    
        {
          title: "Operation",
          render: (text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link to={`/transactiondetail/${record?.id}`}>
                  <Button type="primary" icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
    
              <Tooltip title="Edit">
                {/* <Link to={`/edit-transaction/${record?.id}`}>
                  <Button
                    style={{ background: "black", border: "black" }}
                    type="primary"
                    icon={<EditOutlined />}
                  />
                </Link> */}
              </Tooltip>
    
              <Popconfirm
                title="Are you sure you want to Delete this user?"
                // onConfirm={() => handleMemberDelete(record?.id)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                {/* <Tooltip title="Delete">
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                    />
                </Tooltip> */}
              </Popconfirm>
            </Space>
          ),
        },
      ];

    // return(<SiteLayout><div>Transactions</div></SiteLayout>)
    return (
    <SiteLayout>
        {/* {store?.SelectLoader === true && <Loader />} */}
        <div
            style={{ padding: "30px" }}
            className="checkout-form-container gig-sure-card-wrapper"
        >
            <div className="wrapper_twoDiv">
                <Space style={{ marginBottom: 18 }}>
                    <h3 className="start">Transactions List</h3>
                </Space>

                <div className="end">
                    <Space className="end" style={{ marginBottom: 18 }}>
                        {/* <Link to="/add-transaction">
                            <Button className="primary custom-btn">Add Transaction</Button>
                        </Link> */}
                    </Space>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
    </SiteLayout>
    )

}

export default Transactions