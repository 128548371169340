import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../../Dashboard/Dashboard";
import Members from "../../Members/Members";
import Addmember from "../../Members/Addmember";
import RequireAuth from "../Auth/RequireAuth";
import PageNotFound from "../404/PageNotFound";
import Memberdetail from "../../Members/Memberdetail";
import AddFees from "../../Dfc/AddFees";
import Death from "../../Dfc/Death";
import DFCList from "../../Dfc/DFCList";
import Setting from "../../Setting/Setting";
import AddDeath from "../../Dfc/AddDeath";
import Transactions from "../../Transactions/Transactions";

import About from "../../Home/About/About";
import Terms from "../../Home/Terms/Terms";
import Privacy from "../../Home/Privacy/Privacy";
import Refund from "../../Home/Refund/Refund";
import Contact from "../../Home/Contact/Contact";
import PaymentCheck from "../PaymentCheck/PaymentCheck";
import DFCDetail from "../../Dfc/DFCDetail";
import Home from "../../Home/Home/home";
import UserLogin from "../../Reusable/UserLogin/Login";
import PaymentPage from "../../PaymentPage/PaymentPage";
import Success from "../payment/success";
import failed from "../payment/failed";
import Failed from "../payment/failed";


const mapState = (props) => ({
  CheckLogin: props.AuthReducer.isLoggedInAdminState,
});

function Routings(store) {
  return (
    <Fragment>
      {/* {store.CheckLogin === true && <SiteLayout />} */}

      <Routes>
        <Route exact path="/dashboard" element={<RequireAuth />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route exact path="/members" element={<RequireAuth />}>
          <Route exact path="/members" element={<Members />} />
        </Route>

        {/* changes start ....... */}

        <Route exact path="/fees" element={<RequireAuth />}>
          <Route exact path="/fees" element={<AddFees />} />
        </Route>

        <Route exact path="/death" element={<RequireAuth />}>
          <Route exact path="/death" element={<Death />} />
        </Route>

        <Route exact path="/dfc-list" element={<RequireAuth />}>
          <Route exact path="/dfc-list" element={<DFCList />} />
        </Route>

        <Route exact path="/add-death" element={<RequireAuth />}>
          <Route exact path="/add-death" element={<AddDeath />} />
        </Route>

        <Route exact path="/transactions" element={<RequireAuth />}>
          <Route exact path="/transactions" element={<Transactions />} />
        </Route>

        <Route exact path="/setting" element={<RequireAuth />}>
          <Route exact path="/setting" element={<Setting />} />
        </Route>

        <Route exact path="/edit-death/:id" element={<RequireAuth />}>
          <Route exact path="/edit-death/:id" element={<AddDeath />} />
        </Route>

        <Route exact path="/terms-conditions" element={<Terms />} />

        <Route exact path="/privacy-policy" element={<Privacy />} />

        <Route exact path="/refund-policy" element={<Refund />} />

        <Route exact path="/contact-us" element={<Contact />} />

        <Route exact path="/about-us" element={<About />} />

        <Route exact path="/dfc-payment" element={<PaymentCheck />} />

        {/* changes end ....... */}

        <Route exact path="/add-member/:nextId" element={<RequireAuth />}>
          <Route exact path="/add-member/:nextId" element={<Addmember />} />
        </Route>

        <Route exact path="/edit-member/:id" element={<RequireAuth />}>
          <Route exact path="/edit-member/:id" element={<Addmember />} />
        </Route>

        <Route exact path="/memberdetail/:id" element={<RequireAuth />}>
          <Route exact path="/memberdetail/:id" element={<Memberdetail />} />
        </Route>

        <Route exact path="/dfc/:id" element={<RequireAuth />}>
          <Route exact path="/dfc/:id" element={<DFCDetail />} />
        </Route>

        <Route path="/" element={<Home />} exact />

        <Route path="/login" element={<Login />} />
        <Route path="/user-login" element={<UserLogin />} />
        <Route path="/user-payment" element={<PaymentPage />} />
        <Route path="/payment/success" element={<Success />} />
        <Route path="/payment/failure" element={<Failed />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Fragment>
  );
}

export default connect(mapState, null)(Routings);
