import {
    ADD_DEATH_MEMBER,
    SUBMIT_LOADER,
    NULL_DEATH_MEMBER
} from "../Action/ActionTypes";

const initialState = {
    success: false,
    addMember: null,
    addmemberdetails: null,
    error: false,
    loading: false,
};

export default function AddDeathReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case ADD_DEATH_MEMBER:
            // console.log("add reducer--------------");
            return {
                ...state,
                success: true,
                addMember: true,
                addmemberdetails: payload,
                loading: false,
            };

        case NULL_DEATH_MEMBER:
            // console.log("null reducer--------------");
            return {
                ...state,
                success: false,
                addMember: false,
                addmemberdetails: null,
                loading: false,
            };

        case SUBMIT_LOADER:
            return {
                ...state,
                loading: payload,
            };

        default:
            return state;
    }

}
