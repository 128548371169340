import React,{ useEffect } from "react";
import { Card, Row, Col } from "antd";
import Loader from "../../Component/Reusable/Loader/Loader";
import { Link } from "react-router-dom";
import { fetchDashboardData } from "../../Action/Dashboard"
import { useSelector, useDispatch } from "react-redux";

import {
  DollarOutlined,
  InfoCircleOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import "./dashboard.style.scss";

import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";




function Dashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state?.DashboardReducer);
  const dashBoardData = dashboard?.dashboardData;
  const totalDFCAmount = dashBoardData?.totalCollectedDFCAmount?.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })

  // API call 

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, []);

  return (
    <SiteLayout>
    {dashboard.loading === true && <Loader />}
    <div className="dashboard">
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
          <Link to="/members">
            <Card
              title={"Total Active Members"}
              bordered={true}
              className="Card1"
              hoverable
              extra={<SafetyCertificateOutlined />}
            >
              Active Members : {dashBoardData?.activeUserCount || "-"}
            </Card>
          </Link>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
          <Link to="/members">
            <Card
              title={"Total Inactive Users"}
              bordered={true}
              className="Card2"
              hoverable
              extra={<InfoCircleOutlined />}
            >
              Inactive Users : {dashBoardData?.inactiveUserCount || "-"}
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
          <Link to="/fees">
            <Card
              title="Total DFC Amount"
              bordered={true}
              className="Card3"
              hoverable
              extra={<DollarOutlined />}
            >
              Collected Amount : {totalDFCAmount  || "-"}
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  </SiteLayout>
  );
}
export default Dashboard;
