/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Helmet } from "react-helmet";
// import styles from "../style.module.scss";
import Headernew from '../../Reusable/Main-Layput/Headernew'
// import "../custom.css";
import Footernew from '../../Reusable/Main-Layput/Footernew';
import styles from './style.module.scss'


const About = () => {
  return (
    <>
      <Headernew />
      <Helmet title="Overview" />
      <section id="slider" className="slider-element swiper_wrapper min-vh-75 justify-content-start dark" style={{background: '#063639'}}>
        <div className="container">
          <div className="row h-100 align-items-center justify-content-between">
            <div className="col-lg-4 col-md-6 py-5 py-md-0">
              <div className="heading-block border-bottom-0 mb-4">
                <h5 className="mb-1 text-uppercase ls-2 color op-06">About Us</h5>
                <h2 className="mb-4 text-transform-none">Shri Samasta Dashashrimali Sukhadiya Suraksha Trust</h2>
              </div>
              <div className="svg-line mb-4">
                <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={20} />
              </div>
              <p className="mb-5">SCHEME NO. 35/2018 | REGISTRATION NO. A/3812 (AHMEDABAD)</p>
              <a href="/contact-us" className="button button-rounded button-xlarge bg-color bg-color-shadow button-light text-dark shadow text-transform-none ls-0 m-0">Contact Us</a>
            </div>
            <div className="col-lg-8 col-md-6">
              <img src="demos/nonprofit/images/about/1.png" alt="Image" className="full-width-img" />
            </div>
          </div>
        </div>
      </section>
      <section id="content">
        <div className="content-wrap py-0 overflow-visible">
        <div className="container">
            <div className="slider-feature">
              <div className="row gutter-10 justify-content-center">
                <div className="col-md-4">
                  <div className="card text-center border-start-0 border-end-0 border-top-0 border-bottom border-bottom shadow py-3 rounded-0 fw-semibold text-uppercase ls-1 h-translate-y-sm all-ts">
                    <div className="card-body">
                      <img src="demos/nonprofit/images/icons/volunteers.svg" alt="Counter Icon" width={70} className="mb-4" />
                      <div className="counter font-secondary"><span data-from={100} data-to={2500} data-refresh-interval={50} data-speed={2200} data-comma="true" /></div>
                      <h5 className="text-transform-none ls-0 mb-0 mt-2 text-muted">Medical</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card text-center border-start-0 border-end-0 border-top-0 border-bottom border-bottom shadow py-3 rounded-0 fw-semibold text-uppercase ls-1 h-translate-y-sm all-ts">
                    <div className="card-body">
                      <img src="demos/nonprofit/images/icons/educated.svg" alt="Counter Icon" width={70} className="mb-4" />
                      <div className="counter font-secondary"><span data-from={100} data-to={17408} data-refresh-interval={25} data-speed={2300} data-comma="true" /></div>
                      <h5 className="text-transform-none ls-0 mb-0 mt-2 text-muted">Education</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card text-center border-start-0 border-end-0 border-top-0 border-bottom border-bottom shadow py-3 rounded-0 fw-semibold text-uppercase ls-1 h-translate-y-sm all-ts">
                    <div className="card-body">
                      <img src="demos/nonprofit/images/icons/shelter.svg" alt="Counter Icon" width={70} className="mb-4" />
                      <div className="counter font-secondary"><span data-from={150} data-to={13413} data-refresh-interval={50} data-speed={2100} data-comma="true" /></div>
                      <h5 className="text-transform-none ls-0 mb-0 mt-2 text-muted">Social Welfare</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="section m-0 bg-transparent pt-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3 className="mb-2">Our Mission</h3>
                  <div className="svg-line mb-2">
                    <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                  </div>
                  <div className="row mission-goals gutter-30 mb-0">
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/home.svg" alt="Featured Icon 1" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>To enhance the religious and organizational sense among the Samasta Dashashrimali Sukhadiya community.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/health.svg" alt="Featured Icon 2" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Provide financial assistance to families at the time of a member's death.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/education.svg" alt="Featured Icon 3" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Offer support during medical emergencies to members.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/animal.svg" alt="Featured Icon 4" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Work towards the comprehensive development and interest of members.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/environmental.svg" alt="Featured Icon 5" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Improve physical health through seminars, yoga, exercises, and promote a healthy society.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/food.svg" alt="Featured Icon 6" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Act as a financial custodian for member security.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/food.svg" alt="Featured Icon 6" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Organize educational, medical, and social welfare camps.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                          <img src="demos/nonprofit/images/icons/food.svg" alt="Featured Icon 6" width={50} />
                        </div>
                        <div className="fbox-content">
                          <p>Organize educational, medical, and social welfare camps.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div className="section mt-3" style={{background: 'url("demos/nonprofit/images/others/section-bg.jpg") no-repeat center center / cover', padding: '80px 0'}}>
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-7 text-center">
                      <div className="heading-block border-bottom-0 mb-4">
                        <h2 className="mb-4 text-transform-none">How to Become a Member</h2>
                      </div>
                      <div className="svg-line mb-4">
                        <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={20} />
                      </div>
              </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                <div className="row mission-goals gutter-30 mb-0">
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                        </div>
                        <div className="fbox-content">
                          <p>Individuals over 18 years from the Samasta Dashashrimali Sukhadiya caste can apply.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                        </div>
                        <div className="fbox-content">
                          <p>Membership is granted upon payment of the admission fee, membership fee, and ADFC total amount.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                        </div>
                        <div className="fbox-content">
                          <p>Members admitted at the inception of the trust will be subject to the initial scheme's rules.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-box fbox-plain bg-white mx-0">
                        <div className="fbox-media position-relative col-auto p-0 me-4">
                        </div>
                        <div className="fbox-content">
                          <p>The Board of Trustees has the authority to admit or reject any membership application.</p>
                        </div>
                      </div>
                    </div>
                </div>
                <br/>
                <br/>
                <h4 className="mb-2">Rules for Getting the Membership</h4>
                  <div className="svg-line mb-2">
                    <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={10} />
                  </div>
                <ul>
                  <li>Submit a completed application form with details of two nominees.</li>
                  <li>Provide two passport size photographs.</li>
                  <li>Submit proof of age with any government document listed.</li>
                  <li>All payments should be made in the name of the trust.</li>
                  <li>Membership fee, admission fee, and administrative contribution are non-refundable.</li>
                  <li>ADFC amount is taken as a security deposit, with unused amounts refundable.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg-transparent">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="heading-block border-bottom-0 mb-4">
                  <h2 className="mb-4 text-transform-none">DFC Contribution</h2>
                </div>
                <div className="svg-line mb-4">
                  <img src="demos/nonprofit/images/divider-1.svg" alt="svg divider" height={15} />
                </div>
                <ol>
            <li>Notification for DFC payment will be issued after the death of every three members.</li>
            <li>Payments must be made within specified time frames, with late fees applicable.</li>
            <li>Per death, a specific amount is allocated to the deceased member's nominee and the corpus fund.</li>
            <li>Continuous DFC contribution is required for 20 years, after which contributions are drawn from the corpus fund.</li>
            <li>DFC contributions can be made via cash, cheque, or DD. Late payments must be in cash.</li>
        </ol>
              </div>
            </div>
          </div>
        </div>



        </div>
      </section>
      <Footernew />
      </>
  );
};

export default About;
